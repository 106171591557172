'use client'

import React, { useState } from 'react'
import InputMask from 'react-input-mask';

  
  interface SimpleInputPhoneProps {
    label:string;
    placeholder:string;
    value: string;
    type?: string;
    required?: boolean;
    setvalue: (setvalue: string) => void;
    
  }
  const SimpleInputPhone: React.FC<SimpleInputPhoneProps>=({value, setvalue, label, placeholder, required=false, type="text"})=>{
    const [mask_text, setmask_text]=useState(19)
    
    const editValue = (e: React.ChangeEvent<HTMLInputElement>) => {

      var val=e.target.value
       
        setvalue(val)
     
     
    };
  return (
    <div className={`input`}>
        <label>{label}</label>
        <div className={`input_input  ${required && (value===null || value.replaceAll("_", "").length!==19)?"red_input_box":""}`}>
           <InputMask 
           mask="+\9\98 (99) 999-99-99"
           maskChar="_" type={type} placeholder={placeholder} value={value!==null?value:''} onChange={(e:any)=>{editValue(e)}}/>
        </div>
      </div>
  )
}
export default SimpleInputPhone;