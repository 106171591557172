import { PuffLoader } from "react-spinners"


const Loader=()=>{
  return(
    <div className="loader">
        
<PuffLoader color="#00a2b7" />
    </div>
  )
}
export default Loader