
import React from 'react'
import { FaCar, FaClock, FaLocationDot } from 'react-icons/fa6'
import home from '../../../images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import ImageBox from '../../ImageBox';
import { useTranslation } from 'react-i18next';
import { CarObject, useStore } from '../../../store/Store';
import { filterPrice, formatDate } from '../../Alert';
import { IoSettingsSharp } from 'react-icons/io5';

interface MultipleSelectProps {
    data:CarObject;number_key:number;
   }
   const CardCarMini: React.FC<MultipleSelectProps>=({data, number_key})=>{
   const {t}=useTranslation() 
   const {setold_link}=useStore()
   const {money_type}=useStore()
 let location = useLocation();
  return (
      
        <Link onClick={()=>{setold_link(location.search)}} to={"/product/car/"+data.id} className='card card_mini'>
            {/* <div className="lenta_card">
                <h1>TOP</h1>
            </div> */}
            <div className='card_mini_img card_img'>
            <ImageBox  alt='image'  src={data.image!==null?data.image.image:home} />
            </div>
            <div className='card_mini_text'>
                <div className='card_mini_text_head'>
                <h1>{data.name!=null?data.name:''}</h1>
                <div className='card_mini_price card_price'>
                    <p>{money_type===2?filterPrice(data.price_usd)+" $":filterPrice(data.price_uzs)+t(t(" so'm"))}</p>
                    <span>{data.agreed?t("agreed"):<span className='white_text'>.</span>}</span>
                </div>
                </div>
                <div className='card_mini_address'>
                <div className='card_item card_mini_item'>
                        <span><FaLocationDot /></span>
                        <p>{data.region!=null?data.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {data.district!=null?data.district[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</p>
                        </div>
                    <div className='card_item card_mini_item'>
                        <span><FaCar /></span>
                        <p>{data.ctype!=null?data.ctype[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}{data.model!=null?', '+data.model[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}{data.position!=null?', '+data.position[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}{data.transmission!=null?', '+data.transmission[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}{data.kuzov!=null?', '+data.kuzov[`name_${t("lang")==='ru'?'ru':'uz'}`]:''} </p>
                        </div>
                    <div className='card_item card_mini_item'>
                        <span><IoSettingsSharp /></span>
                          <p>{data.probeg!==null?filterPrice(data.probeg)+" "+t("km"):''} {data.year!==null?", "+data.year+" "+t("year"):''}{data.drive_unit!=null?', '+data.drive_unit[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}{data.engine_type!=null?', '+data.engine_type[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</p>
                        </div>
                        <div className='card_item card_time'>
                        <span><FaClock /></span>
                        <p>{formatDate(data.created_at)}</p>
                        </div>
                </div>
                 {/* <div className='card_mini_icon card_icon'><FaRegBookmark /></div> */}
            </div>
        </Link>
  )
}
export default CardCarMini