'use client';
import React, { useEffect, useState } from 'react'
import EmblaCarousel from '../../components/carousel/EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel';
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CreditObject, useStore } from '../../store/Store';
import { httpGetRequest } from '../../host/Host';
import { filterPrice, formatFacebook, formatInstagram, formatTelegram, formatwebsite, responsive } from '../../components/Alert';
import avatar from '../../images/icons/random_avatar_man.jpg'
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF, FaGlobe, FaInstagram } from 'react-icons/fa6';
import { TbArrowBigLeftFilled } from 'react-icons/tb';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';

import CardCarCreditMini from '../../components/cards/main_cards/CardCarCreditMini';
const OPTIONS: EmblaOptionsType = {}
export default function CarCreditProduct() {
    const {t}=useTranslation()
    const {id}=useParams()
    const navigate=useNavigate()
    const [data, setdata]=useState<CreditObject|null>(null)
    const [number, setnumber]=useState(0)
    const {setloader, money_type, old_link}=useStore()
    const [as_add_data, setas_add_data]=useState<CreditObject[]>([])
    const [user_add_data, setuser_add_data]=useState<CreditObject[]>([])
    useEffect(()=>{
     
     getData()
    }, [id])
    const getData=async()=>{
        setloader(true)
        try{
            var res=await httpGetRequest(`/auto-credits/${id}/detail/`)
            setdata(res.data)
            getAsAdds(res.data)
            if(res.data.created_by!==null){
              getUserAdds(res.data.created_by.id)
            }
            setloader(false)
            setnumber(number+1)
        }catch(err){
            setloader(false)
            navigate('/products?big_category=2&category=8')
        }
          
    }
    
    const getAsAdds=async(a:CreditObject)=>{
      var config={
        itype__in:a.itype!==null?String(a.itype.id):null, 
        b_type__in:a.b_type!==null?String(a.b_type.id):null, 
        region__in:a.region!==null?String(a.region.id):null, 
        page:1,
        status:1
      }
      try{
        var res=await httpGetRequest('/auto-credits/', config)
        setas_add_data(res.data.results)
        setnumber(number+1)
        }catch(err){
        console.log(err)
        }
}
const getUserAdds=async(user_id:number)=>{
 try{
    var res=await httpGetRequest('/auto-credits/'+user_id, {status:1, page:1})
    setuser_add_data(res.data.results)
    setnumber(number+1)
    }catch(err){
    console.log(err)
    }
}

    const formatOldLink = (link:string) => {
      if (link.length === 0) {
        return "/products?big_category=2&category=8";
      } else {
        return "/products" + link;
      }
    };
  return (
    <div className='box'>
        {data!==null?
        <>
        <div  className='product_img_box'>
        <div  className='product_img_box_box'>
         <div className='product_img'>
         <EmblaCarousel images={data.images} options={OPTIONS} />
        
         </div>
         </div>
         {data.video!==null?<div className="yandex_map_box video_full_box">
            <div className='yandex_box'>
          <video controls src={data.video}></video>
        </div>
         </div>:<></>}
         <div className="yandex_map_box video_full_box">
            <div className='yandex_box'>
         <YMaps>
      <Map defaultState={{
        center: [data.latitude!==null?Number(data.latitude):0,data.longitude!==null?Number(data.longitude):0],
        zoom: 14,
      }} width="100%" height="100%" options={{
        minZoom: 5,
        maxZoom: 20,
      }}>
        <Placemark geometry={[data.latitude!==null?Number(data.latitude):0,data.longitude!==null?Number(data.longitude):0]} properties={{ hintContent: 'My Placemark', balloonContent: 'This is my placemark!' }} />
        <ZoomControl options={{ float: 'left' }} />
      </Map>
   
    </YMaps>
   
    </div>
    </div>
         </div>
         <div className='product_text'>
         <div className='product_text_head'>
          {data.created_by!==null?<div className='user_box'>
                <div className='avatar' style={{backgroundImage:`url(${data.created_by!==null && data.created_by.image!==null?data.created_by.image:avatar})`}}  />
                <p>{data.created_by.first_name}</p>
            </div>:<></>}
           
   
            <div className='add_contact_box'>
            <Link className='add_contact_icon' to={formatOldLink(old_link)}><TbArrowBigLeftFilled /></Link>
            {data.telegram!==null && data.telegram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatTelegram(data.telegram)}><FaTelegramPlane /></a>:<></>}
            {data.instagram!==null && data.instagram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatInstagram(data.instagram)}><FaInstagram /></a>:<></>}
            {data.facebook!==null && data.facebook.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatFacebook(data.facebook)}><FaFacebookF /></a>:<></>}
            {data.website!==null && data.website.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatwebsite(data.website)}><FaGlobe /></a>:<></>}
            
            {data.phone!==null && data.phone.length!==0?<a href={`tel: ${data.phone}`}>{data.phone}</a>:<></>}
            </div>
         </div>
            <div className='product_text_head'>
                <div className='head_text_title'>
            <h1>{data.name}</h1>
            <p className='product_price'>{filterPrice(data.summ_min)+" so'm - "+filterPrice(data.summ_max)+t(t(" so'm"))}</p>
            </div>
            </div>
            <div className='product_box'>
            {data.region!=null || data.district!=null?<div className='product_item'>
            <span>{t("address")} : {data.region!=null?data.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {data.district!=null?data.district[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</span>
            </div>:<></>}
            {data.itype!=null?<div className='product_item'>
            <span>{t("credit_type")} : {data.itype[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            <div className='product_item'>
            <span>{t("percent_credit")}: {filterPrice(data.p_min)+(data.p_max===data.p_min || data.p_max===null?"":" - "+filterPrice(data.p_max))+" %"}</span>
            </div>
            <div className='product_item'>
            <span>{t("start_payment_credit")}: {filterPrice(data.init_pay_percent_min)+(data.init_pay_percent_max===data.init_pay_percent_min || data.init_pay_percent_max===null?"":" - "+filterPrice(data.init_pay_percent_max))+" %"}</span>
            </div>
            <div className='product_item'>
            <span>{t("credit_month_credit")}: {data.month_min+(data.month_max===data.month_min || data.month_max===null?"":" - "+data.month_max)+" oy"}</span>
            </div>
            {data.bonus_month!=null?<div className='product_item'>
            <span>{t("bonus_month_credit")} : {data.bonus_month} oy</span>
            </div>:<></>}
          
            
          
            </div>
            {data.additional!==null && data.additional.length!==0?<div style={{width:'100%', padding:'0'}} className='product_comment'>
               <p dangerouslySetInnerHTML={{__html:data.additional}}/> 
            </div>:''}
         </div>
         
            {as_add_data.length>1?<div className='add_car_box'>
       <h2 className='as_add_title'>{t("as_adds")}</h2>
       <Carousel 
       swipeable={true}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} 
       infinite={true}
       autoPlay={false}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
       >
 {as_add_data.map((item, key)=>{
 if(item.id!==Number(id)){
  return(<div className='as_add_car_card'>
  <CardCarCreditMini  data={item} key={key} number_key={key}/>
      </div>)
  
}
 })}
 
 
  
</Carousel>
       </div>:<></>}

       {user_add_data.length>1?<div className='add_car_box'>
       <h2 className='as_add_title'>{t("user_adds")}</h2>
       <Carousel 
       swipeable={true}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} 
       infinite={true}
       autoPlay={false}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
       >
 {user_add_data.map((item, key)=>{
  if(item.id!==Number(id)){
    return(<div className='as_add_car_card'>
    <CardCarCreditMini  data={item} key={key} number_key={key}/>
        </div>)
    
  }
})}
 
</Carousel>
       </div>:<></>}
            
            
            </>
            :<></>}
    </div>
  )
}
