
import Profile from './Profile'
import SideBar from '../../components/client/SideBar'
import { Route, Routes, useNavigate } from 'react-router-dom'
import HomeAnnoucment from './home/HomeAnnoucment'
import HomeAnnoucmentCreate from './home/create_forms/HomeAnnoucmentCreate'
import { useEffect } from 'react'
import { useStore } from '../../store/Store'
import { httpGetRequest } from '../../host/Host'
import HomeAnnoucmentEdit from './home/create_forms/HomeAnnoucmentEdit'
import CarAnnoucment from './cars/CarAnnoucment'
import CarAnnoucmentEdit from './cars/create_forms/CarAnnoucmentEdit'
import CarAnnoucmentCreate from './cars/create_forms/CarAnnoucmentCreate'
import HomeProductAnnoucmentEdit from './home/create_forms/HomeProductAnnoucmentEdit'
import HomeProductAnnoucmentCreate from './home/create_forms/HomeProductAnnoucmentCreate'
import HomeProductAnnoucment from './home/HomeProductAnnoucment'
import HomeServiceAnnoucmentEdit from './home/create_forms/HomeServiceAnnoucmentEdit'
import HomeServiceAnnoucmentCreate from './home/create_forms/HomeServiceAnnoucmentCreate'
import HomeServiceAnnoucment from './home/HomeServiceAnnoucment'
import HomeCreditAnnoucmentEdit from './home/create_forms/HomeCreditAnnoucmentEdit'
import HomeCreditAnnoucmentCreate from './home/create_forms/HomeCreditAnnoucmentCreate'
import HomeCreditAnnoucment from './home/HomeCreditAnnoucment'
import CarCreditAnnoucmentEdit from './cars/create_forms/CarCreditAnnoucmentEdit'
import CarCreditAnnoucmentCreate from './cars/create_forms/CarCreditAnnoucmentCreate'
import CarCreditAnnoucment from './cars/CarCreditAnnoucment'
import CarServiceAnnoucmentEdit from './cars/create_forms/CarServiceAnnoucmentEdit'
import CarServiceAnnoucmentCreate from './cars/create_forms/CarServiceAnnoucmentCreate'
import CarServiceAnnoucment from './cars/CarServiceAnnoucment'
import CarProductAnnoucmentEdit from './cars/create_forms/CarProductAnnoucmentEdit'
import CarProductAnnoucmentCreate from './cars/create_forms/CarProductAnnoucmentCreate'
import CarProductAnnoucment from './cars/CarProductAnnoucment'

export default function Client() {
  const {user, setuser, setloader}=useStore()
  const navigate=useNavigate()
  useEffect(()=>{
      if(user===null){
        getUserData()
      }
  }, [])

  const getUserData=async()=>{
    setloader(true)
     try{
       var res=await httpGetRequest("/user/profile/")
       setuser(res.data)
       setloader(false)
     }catch(error){
      console.log(error)
      navigate("/login")
      setloader(false)
     }
  }
  return (
   
    <div className='client_box client'>
      
        
            <SideBar/>
       
        <div className='client_content'>
          <Routes>
            <Route path='' element={<Profile/>}/>
            <Route path='home/home' element={<HomeAnnoucment/>}/>
            <Route path='home/home/edit/:id' element={<HomeAnnoucmentEdit/>}/>
            <Route path='home/home/create' element={<HomeAnnoucmentCreate/>}/>
            <Route path='home/product' element={<HomeProductAnnoucment/>}/>
            <Route path='home/product/edit/:id' element={<HomeProductAnnoucmentEdit/>}/>
            <Route path='home/product/create' element={<HomeProductAnnoucmentCreate/>}/>
            <Route path='home/service' element={<HomeServiceAnnoucment/>}/>
            <Route path='home/service/edit/:id' element={<HomeServiceAnnoucmentEdit/>}/>
            <Route path='home/service/create' element={<HomeServiceAnnoucmentCreate/>}/>
            <Route path='home/credit' element={<HomeCreditAnnoucment/>}/>
            <Route path='home/credit/edit/:id' element={<HomeCreditAnnoucmentEdit/>}/>
            <Route path='home/credit/create' element={<HomeCreditAnnoucmentCreate/>}/>
            <Route path='car/credit' element={<CarCreditAnnoucment/>}/>
            <Route path='car/credit/edit/:id' element={<CarCreditAnnoucmentEdit/>}/>
            <Route path='car/credit/create' element={<CarCreditAnnoucmentCreate/>}/>
            <Route path='car/service' element={<CarServiceAnnoucment/>}/>
            <Route path='car/service/edit/:id' element={<CarServiceAnnoucmentEdit/>}/>
            <Route path='car/service/create' element={<CarServiceAnnoucmentCreate/>}/>
            <Route path='car/product' element={<CarProductAnnoucment/>}/>
            <Route path='car/product/edit/:id' element={<CarProductAnnoucmentEdit/>}/>
            <Route path='car/product/create' element={<CarProductAnnoucmentCreate/>}/>
            <Route path='car/car' element={<CarAnnoucment/>}/>
            <Route path='car/car/edit/:id' element={<CarAnnoucmentEdit/>}/>
            <Route path='car/car/create' element={<CarAnnoucmentCreate/>}/>
          </Routes>
           
        </div>
    </div>
  )
}
