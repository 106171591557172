'use client';
import React, { useState, useRef, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../css/lazy.css'; // Add your custom styles for downloading effect

interface ImageBoxProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageBox: React.FC<ImageBoxProps> = ({ src, alt, className }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current?.complete) {
      setLoaded(true);
    }
  }, []);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={`lazy-image-container ${!loaded ? 'loaded' : ''}`}>
      <LazyLoadImage
        src={src}
        alt={alt}
        effect="blur"
        className={className}
        placeholderSrc={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E`}
        threshold={100}
        afterLoad={handleLoad}
      />
    </div>
  );
};

export default ImageBox;
