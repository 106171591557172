
import { IoHome, IoMenu, IoSettingsSharp } from 'react-icons/io5'
import { Link, useLocation, useNavigation } from 'react-router-dom'
import { FaCar } from 'react-icons/fa6';
import { GiAutoRepair, GiCarWheel, GiTakeMyMoney } from 'react-icons/gi';
import { RiPaintBrushFill } from 'react-icons/ri';
import { GrPaint } from "react-icons/gr";
import { useStore } from '../../store/Store';
import avatar from '../../images/icons/random_avatar_man.jpg'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export default function SideBar() {
    const {user}=useStore()
    let location = useLocation();
    const [loc, setloc]=useState(1)
    const [open, setopen]=useState(false)
    const {t} =useTranslation()
    useEffect(()=>{
       
            if(location.pathname.indexOf('/home/home')!==-1){
                setloc(2)
            }else{
                if(location.pathname.indexOf('home/product')!==-1){
                    setloc(4)
                }else{
                    if(location.pathname.indexOf('car/product')!==-1){
                        setloc(8)
                    }else{
                        if(location.pathname.indexOf('/car/car')!==-1){
                            setloc(6)
                        }else{
                            if(location.pathname.indexOf('/home/service')!==-1){
                                setloc(3)
                            }else{
                                if(location.pathname.indexOf('/car/service')!==-1){
                                    setloc(7)
                                }else{
                                    if(location.pathname.indexOf('/home/credit')!==-1){
                                        setloc(5)
                                    }else{
                                        if(location.pathname.indexOf('/car/credit')!==-1){
                                            setloc(9)
                                        }else{
                                            setloc(1)
                                        }
                                    }
                                    
                                }
                                
                            }
                           
                        }
                       
                    }
                    
                }
                
            }
           
        
        setopen(false)
    }, [location])
  return (
    <>
    <div className={`client_menu ${open?"open_side_bar":''}`}>
    <div className={`client_menu_header`}>
    <Link to={"/"} className='user_box'>
                <div className='avatar'  style={{backgroundImage:`url(${user!==null && user.image!==null?user.image:avatar})`}} />
                <div className='client_user_text'>
                    <h3>{user!=null?user.first_name:''}</h3>
                    <p>{user!=null?user.phone:''}</p>
                </div>
               
            </Link>
            <button onClick={()=>setopen(!open)} className='menu_tab'>
            <IoMenu />
            </button>
    </div>
    <div className='client_menu_list'>
    <Link className={`client_list_item ${loc===1?"active_list_item":''}`} to="">
            <span><IoSettingsSharp /></span>
            <p>{t("parametr")}</p>
        </Link>
       
       
        {/* <Link className={`client_list_item ${loc===1?"active_list_item":''}`} to="announcement">
            <span><FaMoneyBillWave /></span>
            <p>To'lovlar</p>
        </Link> */}
        <div className='client_dropdown'>
        <div className='client_list_item client_dropdown_head'>
            <p>{t("houses")}</p>
        </div>
        <Link className={`client_list_item ${loc===2?"active_list_item":''}`} to="home/home">
            <span><IoHome /></span>
            <p>{t("houses")}</p>
        </Link>
        <Link className={`client_list_item ${loc===3?"active_list_item":''}`} to="home/service">
            <span><RiPaintBrushFill /></span>
            <p>{t("master_service")}</p>
        </Link>
        <Link className={`client_list_item ${loc===4?"active_list_item":''}`} to="home/product">
            <span><GrPaint /></span>
            <p>{t("material")}</p>
        </Link>
        <Link className={`client_list_item ${loc===5?"active_list_item":''}`} to="home/credit">
            <span><GiTakeMyMoney /></span>
            <p>{t("ipoteka")}</p>
        </Link>
        </div>

        <div className='client_dropdown'>
        <div className='client_list_item client_dropdown_head'>
            <p>{t("cars")}</p>
        </div>
        <Link className={`client_list_item ${loc===6?"active_list_item":''}`} to="car/car">
            <span><FaCar /></span>
            <p>{t("cars")}</p>
        </Link>
        <Link className={`client_list_item ${loc===7?"active_list_item":''}`} to="car/service">
            <span><GiAutoRepair /></span>
            <p>{t("master_service")}</p>
        </Link>
        <Link className={`client_list_item ${loc===8?"active_list_item":''}`} to="car/product">
            <span><GiCarWheel /></span>
            <p>{t("material_car")}</p>
        </Link>
        <Link className={`client_list_item ${loc===9?"active_list_item":''}`} to="car/credit">
            <span><GiTakeMyMoney /></span>
            <p>{t("auto_credit")}</p>
        </Link>
        </div>
      
        
        
        
    </div> 
    </div>
    </>
    
  )
}
