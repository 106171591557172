import React from 'react'
import { RiSearchLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next';
interface SearchBoxProps {
  
  search: string;
  setsearch: (search: string) => void;
  getData?: () => void;
  
}
const SearchBox: React.FC<SearchBoxProps>=({search, setsearch, getData})=>{
  const {t} =useTranslation()
  return (
    <>
    <input className='search_input' value={search} onChange={(e)=>{setsearch(e.target.value)}} placeholder={t("search")+"..."} type='text'/>
    <button className='search_button' onClick={getData}><RiSearchLine /></button>
  </>
  )
}
export default SearchBox