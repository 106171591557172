import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar';
import Main from './pages/main/Main';
import Category from './pages/categories/Category';
import Products from './pages/products/Products';
import { useStore } from './store/Store';
import Client from './pages/client/Client';
import { initializeApp, FirebaseOptions } from "@firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import Loader from './components/Loader';
import { getTokenAccess, httpGetRequest } from './host/Host';
import ChangePassword from './pages/login/ChangePassword';
import './locale/i18n'

import HomeProduct from './pages/product/HomeProduct';
import HomeServiceProduct from './pages/product/HomeServiceProduct';
import HomeProductProduct from './pages/product/HomeProductProduct';
import HomeCreditProduct from './pages/product/HomeCreditProduct';
import CarProduct from './pages/product/CarProduct';
import CarServiceProduct from './pages/product/CarServiceProduct';
import CarProductProduct from './pages/product/CarProductProduct';
import CarCreditProduct from './pages/product/CarCreditProduct';
import Selectors from './pages/selectors/Selectors';
import { generateSitemapXml } from './sitemap';
import Footer from './components/Footer';
const firebaseApp = initializeApp(firebaseConfig as FirebaseOptions);

const App: React.FC=()=>{
  const location = useLocation();
  const [getTypes, setgetTypes]=useState(true)
  const navigate = useNavigate();
  const {setisOpen, user, loader,setregion_data, setloader, setuser, 
    setb_type_data, 
    setcredit_type_data,
    setkuzov_data, 
    setengine_type_data, 
    settransmission_data, 
    setdrive_unit_data, 
    sethouse_service_types_data,
    sethouse_service_payment_types_data,
    setcar_product_type_data,
    sethouse_product_type_data,
    setcolor_data, 
    setcolor_state_data, 
    setcar_service_payment_types_data,
    setcmtype_data, 
    setcar_service_types_data,
     sethouse_type_data, setitems_in_data, setmat_type_data, setrepair_data, setnear_data, setsanuzel_data} = useStore()

  const handleClick = (e: React.MouseEvent | MouseEvent) => {
    const target = (e as React.MouseEvent).target as HTMLDivElement; 
  
    if (target && target.className && target.tagName && target.tagName!=='path' && target.tagName!=='svg' && target.tagName!=='rect' && target.tagName!=='line') {
      var c=target.className
      console.log('Body clicked!', target.tagName);
      if(c.indexOf('icon_nav')===-1){
        setisOpen(false)
      }
      
      
    }
  };
  const urls = [
    { loc: 'https://uyavto.uz', lastmod: '2024-05-01' },
    { loc: 'https://uyavto.uz/categories', lastmod: '2024-05-02' },
    { loc: 'https://uyavto.uz/selectors', lastmod: '2024-05-02' },
    { loc: 'https://uyavto.uz/products', lastmod: '2024-05-02' },
    { loc: 'https://uyavto.uz/product', lastmod: '2024-05-02' },
    { loc: 'https://uyavto.uz/client', lastmod: '2024-05-02' },
    { loc: 'https://uyavto.uz/login', lastmod: '2024-05-02' },
    // Add more URLs as needed
  ];

  const sitemapXml = generateSitemapXml(urls);

  // Write the sitemap XML to a file or display it in your application
  console.log(sitemapXml);
  useEffect(() => {
    if(user===null){
      getUserData()
    }
    window.scrollTo(0, 0);
   
  window.addEventListener('click', handleClick as EventListener);

  
  return () => {
    window.removeEventListener('click', handleClick as EventListener);
  };
  }, [location, navigate]);

 
const getUserData=async()=>{
  var token=getTokenAccess()
  if(token!==null){
    setloader(true)
    try{
      var res=await httpGetRequest("/user/profile/")
    
      setuser(res.data)
      setloader(false)
    }catch(error){
     console.log(error)
     
     setloader(false)
    }
  }
  if(getTypes){
    getFilters()
  }

}

const getFilters=async()=>{
  var res=await httpGetRequest('/house-selections/')
  setb_type_data(res.data.b_type)
  sethouse_type_data(res.data.house_type)
  setitems_in_data(res.data.items_in)
  setmat_type_data(res.data.mat_type)
  setnear_data(res.data.near)
  setrepair_data(res.data.repair)
  setsanuzel_data(res.data.sanuzel)
  sethouse_product_type_data(res.data.product_type)
  var res3=await httpGetRequest('/car-selections/')
 setkuzov_data(res3.data.kuzov)
  setengine_type_data(res3.data.engine_type)
  settransmission_data(res3.data.transmission)
  setdrive_unit_data(res3.data.drive_unit)
  setcolor_data(res3.data.color)
  setcolor_state_data(res3.data.color_state)
  setcmtype_data(res3.data.cmtype)
  setcar_product_type_data(res3.data.product_type)
  var res1=await httpGetRequest('/regions/')
  setregion_data(res1.data)
  var res2=await httpGetRequest('/houses-products/types/')
  sethouse_product_type_data(res2.data)
  var res4=await await httpGetRequest('/house-services-selections/')
  sethouse_service_types_data(res4.data.service_types)
  sethouse_service_payment_types_data(res4.data.payment_type)
  var res7=await httpGetRequest('/cars-services-selections/')
  setcar_service_types_data(res7.data.service_types)
  setcar_service_payment_types_data(res7.data.payment_type)

  var res6=await httpGetRequest('/credits-selections/')
  setcredit_type_data(res6.data.itype)
  setgetTypes(false)
}

  return (
    <div>
      {loader?<Loader/>:<></>}
      <Navbar/>
      <Routes>
        <Route path='/' element={<Main/>}/>
        <Route path='/client/*' element={<Client/>}/>
        <Route path='/categories' element={<Category/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/selectors' element={<Selectors/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/change-password' element={<ChangePassword/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/product/home/:id' element={<HomeProduct/>}/>
        <Route path='/product/car/:id' element={<CarProduct/>}/>
        <Route path='/product/home/service/:id' element={<HomeServiceProduct/>}/>
        <Route path='/product/car/service/:id' element={<CarServiceProduct/>}/>
        <Route path='/product/home/product/:id' element={<HomeProductProduct/>}/>
        <Route path='/product/car/product/:id' element={<CarProductProduct/>}/>
        <Route path='/product/home/credit/:id' element={<HomeCreditProduct/>}/>
        <Route path='/product/car/credit/:id' element={<CarCreditProduct/>}/>
      </Routes>
      <Footer/>
    </div>
  )
}
export default App;