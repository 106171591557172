import { Col } from 'antd'
import React from 'react'
import {  FaRegBookmark, FaRegCalendarCheck } from 'react-icons/fa6'
import home from '../../../images/logo.png';
import {Variants, motion} from 'framer-motion'
import { GiPayMoney} from 'react-icons/gi';
import { Link, useLocation } from 'react-router-dom';
import ImageBox from '../../ImageBox';
import { useTranslation } from 'react-i18next';
import { CreditObject, useStore} from '../../../store/Store';
import { filterPrice} from '../../Alert';
import { PiPercentFill } from "react-icons/pi";

  interface MultipleSelectProps {
   data:CreditObject;number_key:number;
  }
  const CardHomeCredit: React.FC<MultipleSelectProps>=({data, number_key})=>{
  const {t}=useTranslation()
  let location = useLocation();
  const {money_type, setold_link}=useStore()
  return (
    <Col lg={24} md={24} sm={24}>
        <div>
        <div>
        <Link   onClick={()=>{setold_link(location.search)}}  to={"/product/home/credit/"+data.id} className='card'>
            {/* <div className="lenta_card">
                <h1>TOP</h1>
            </div> */}
            <div className='card_img'>
            <ImageBox alt='image'  src={data.image!==null?data.image.image:home} />
            </div>
            <div className='card_text'>
                <div className='card_text_head'>
                <h1>{data.name!=null?data.name:''}</h1>
                <div className='card_price'>
                <p>{filterPrice(data.summ_min)+" so'm - "+filterPrice(data.summ_max)+t(t(" so'm"))}</p>
                   {data.bonus_month!==null && data.bonus_month!==0?<span className='card_time'>{t("bonus_month_credit")}: {data.bonus_month+" oy"}</span>:<></>} 
                    
                </div>
                </div>
                <div className='card_address'>
                <div className='card_item'>
                        <span><FaRegCalendarCheck /></span>
                        <p>{t("credit_month_credit")}: {data.month_min+(data.month_max===data.month_min || data.month_max===null?"":" - "+data.month_max)+" oy"}</p>
                        </div>
                <div className='card_item'>
                        <span><PiPercentFill /></span>
                        <p>{t("percent_credit")}: {filterPrice(data.p_min)+(data.p_max===data.p_min || data.p_max===null?"":" - "+filterPrice(data.p_max))+" %"}</p>
                        </div>
                        <div className='card_item'>
                        <span><GiPayMoney /></span>
                        <p>{t("start_payment_credit")}: {filterPrice(data.init_pay_percent_min)+(data.init_pay_percent_max===data.init_pay_percent_min || data.init_pay_percent_max===null?"":" - "+filterPrice(data.init_pay_percent_max))+" %"}</p>
                        </div>
                       
                        
                 </div>
                <div className='card_icon'><FaRegBookmark /></div>
            </div>
        </Link>
        </div>
        </div>
    </Col>
  )
}
export default CardHomeCredit