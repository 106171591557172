import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../images/logo_white.png'
import { Link } from 'react-router-dom'
import { FaTelegramPlane } from 'react-icons/fa'
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa6'
export default function Footer() {
  const {t}=useTranslation()
  return (
    <div className='footer'>
        <div className='footer_img'>
          <img src={logo}/>
        </div>
        <div className='footer_map'>
        <div className='footer_list'>
            <h1> {t("houses")}</h1>
            <Link to={"/selectors?big_category=1&category=1"}>{t("turar_joy")}</Link>
            <Link to={"/selectors?big_category=1&category=2"}>{t("master_service")}</Link>
            <Link to={"/selectors?big_category=1&category=3"}>{t("material")}</Link>
            <Link to={"/selectors?big_category=1&category=4"}>{t("ipoteka")}</Link>
          </div>
          <div className='footer_list'>
            <h1> {t("cars")}</h1>
            <Link to={"/selectors?big_category=1&category=1"}>{t("cars")}</Link>
            <Link to={"/selectors?big_category=1&category=2"}>{t("master_service")}</Link>
            <Link to={"/selectors?big_category=1&category=3"}>{t("material_car")}</Link>
            <Link to={"/selectors?big_category=1&category=4"}>{t("auto_credit")}</Link>
          </div>
        </div>
        <div className='footer_links'>
        <a href="#">NOROV PRO</a>
        <a href="https://maps.app.goo.gl/ZYeYGEectGK6993g8" target='_blank'>{t("address_text")}</a>
        <a href="tel: 998955123434" target='_blank'>+998 (95) 512-34-34</a>
        <a href="mailto: norovsharofiddin@gmail.com" target='_blank'>norovsharofiddin@gmail.com</a>
        <div className="footer_links_box">
        <a href="https://t.me/Uy_avto_Uz" target='_blank'><FaTelegramPlane /></a>
        <a href="https://www.instagram.com/uyavto.uz?igsh=ZW53Z2E0M2w4cDBu" target='_blank'><FaInstagram /></a>
        <a href="https://www.facebook.com/uyavto.uz" target='_blank'><FaFacebookF /></a>
        <a href="https://youtube.com/@uyavtouz?si=v883Sy-dFT2LSXBK" target='_blank'><FaYoutube /></a>
        </div>
       
        </div>
    </div>
  )
}
