import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import SimpleInputText from '../../components/SimpleInputText'
import SimpleSelect from '../../components/SimpleSelect'
import { FaPencil, FaTriangleExclamation } from 'react-icons/fa6'
import { useStore } from '../../store/Store'
import { httpPostRequest, httpPatchRequest, httpGetRequest } from '../../host/Host'
import { dangerAlert } from '../../components/Alert'
import avatar from '../../images/icons/random_avatar_man.jpg'
import SimpleInputPhone from '../../components/SimpleInputPhone'
import { useTranslation } from 'react-i18next'
export default function Profile() {

    const {user, setloader, setuser, district_data, region_data, setdistrict_data}=useStore()
    const [required, setrequired]=useState(false)
    const [required_password, setrequired_password]=useState(false)
    const [first_name, setfirst_name]=useState('')
    const [image, setimage]=useState<File|null>(null)
   
    const [password, setpassword]=useState('')
    const [confirm_password, setconfirm_password]=useState('')
    const [region, setregion]=useState(0)
    const [district, setdistrict]=useState(0)
    const [facebook, setfacebook]=useState('')
    const [instagram, setinstagram]=useState('')
    const [website, setwebsite]=useState('')
    const [telegram, settelegram]=useState('')
    const [phone, setphone]=useState('')
    const [last_name, setlast_name]=useState('')
    
    const [gender, setgender]=useState(0)
    const {t} =useTranslation()
    const handleChange=()=>{

    }
    useEffect(()=>{
        if(user!==null){
            setfirst_name(user.first_name!=null?user.first_name:'')
            settelegram(user.telegram!=null?user.telegram:'')
            setfacebook(user.facebook!=null?user.facebook:'')
            setinstagram(user.instagram!=null?user.instagram:'')
            setlast_name(user.last_name!=null?user.last_name:'')
            setphone(user.phone!=null?user.phone:'')
            setwebsite(user.website!=null?user.website:'')
            if(user.region!=null){
                setregion(user.region.id)
                handleregion(user.region.id)
            }
            setgender(user.gender!=null?user.gender==="male"?1:2:0)
            
        }
        
    }, [user])
    const sendUserData=async()=>{
        if(first_name.length!==0){
           setloader(true)
           var data=new FormData
           data.append("first_name", first_name)
           data.append("last_name", last_name)
           data.append("phone", phone)
           data.append("instagram", instagram)
           data.append("telegram", telegram)
           data.append("facebook", facebook)
           data.append("website", website)
           if (image !== null) {
            data.append("image", image)
           }
          
           if (region !== 0) {
            data.append("region", String(region));
          }
          
          if (gender !== 0) {
            if(gender===1){
                data.append("gender", "male");
            }else{
                data.append("gender", "female");
            }
           
          }
          
          if (district !== 0) {
            data.append("district", String(district));
          }
           try{
            var res=await httpPatchRequest('/user/update/', data)
            setuser(res.data)
            setloader(false)
        }catch(error){
            console.log(error)
            setloader(false)
        }
            
        }else{
            setrequired(true)
        }
    }
    const changePassword=async()=>{
        if(password.length!==0 && confirm_password.length!==0){
            if(password!==confirm_password){
                dangerAlert(t("parword_not_equal"))
               }else{
           setloader(true)
           try{
            var res=await httpPostRequest('/change-password/', {password, confirm_password})
            setloader(false)
           
        }catch(error){
            console.log(error)
            setloader(false)
    
           }
        }
        }else{
            setrequired_password(true)
        }
    }
    const uploadImage=(e: React.ChangeEvent<HTMLInputElement>)=>{
        if(e.target && e.target.files && e.target.files.length>0){
            setimage(e.target.files[0])
        }
    }
    const handleregion=async(id:number)=>{
        setdistrict(0)
        setdistrict_data([])
        var res=await httpGetRequest('/districts/?region='+id)
        setdistrict_data(res.data)
        if(user?.region!==null && id===user?.region.id){
            setdistrict(user.district!=null?user.district.id:0)
        } 
      }
  return (
    <div className='announcment_client'>
    <div className='profile'>
        
        <div className='profile_img'>
            <div className='images_btns'>
                <button className='images_btn edit_btn'><input onChange={uploadImage} accept='image/*' type='file'/><FaPencil /></button>
            </div>
        <div className='profile_avatar' style={{backgroundImage:`url(${user!==null && user.image!==null?user.image:avatar})`}} />
        <div className='alert_text'> <FaTriangleExclamation className='alert_icon' />{ t("alert_user_profile")}</div>
        </div>
        <div className='profile_box'>
            <Row>
                <Col className='filter_col' lg={24} md={24} sm={24}>
                    <h1 className='client_filter_title'>Shaxsiy ma'lumotlar</h1>
                </Col>
            <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText required={required} placeholder={""} label={t("firstname")} value={first_name} setvalue={setfirst_name}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText required={required} placeholder={""} label={t("lastname")} value={last_name} setvalue={setlast_name}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputPhone required={required} placeholder={""} label={t("phone")} value={phone} setvalue={setphone}/>
                </Col>
                <Col className='filter_col no_overflow'lg={12} md={24} sm={24}>
                <SimpleSelect onchange={handleChange} name="big_category" label={t("gender")} value={gender} setvalue={setgender} data={[
                    {id:1, name_ru:"Мужчина", name_uz:"Erkak"},
                    {id:2, name_ru:"Женщина", name_uz:"Ayol"},
                ]}/>
            </Col>
            <Col className='filter_col' lg={12} md={12} sm={24}>
                <SimpleSelect onchange={handleregion} name="region" label={t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
           
            <Col className='filter_col' lg={12} md={12} sm={24}>
                <SimpleSelect onchange={handleChange} name="district" label={t("district")} value={district} setvalue={setdistrict} data={district_data}/>
            </Col>
            <Col className='filter_col filter_two' lg={24} md={24} sm={24}>
                    <h1 className='client_filter_title'>{t("socials")}</h1>
                </Col>
            <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("Telegram")} value={telegram} setvalue={settelegram}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("Instagram")} value={instagram} setvalue={setinstagram}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("FaceBook")} value={facebook} setvalue={setfacebook}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText placeholder={""} label={t("Vebsite")} value={website} setvalue={setwebsite}/>
                </Col>
                
            </Row>
            <div className='filter_btns'>
            <button onClick={sendUserData} className='save_btn'>{t("save")}</button>
        </div>
        <Row>
        <Col className='filter_col filter_two' lg={24} md={24} sm={24}>
                    <h1 className='client_filter_title'>{t("change_password")}</h1>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText type='password' required={required_password} placeholder={""} label={t("new_password")} value={password} setvalue={setpassword}/>
                </Col>
                <Col  className='filter_col' lg={12} md={24} sm={24}>
                <SimpleInputText type='password' required={required_password} placeholder={""} label={t("check_password")} value={confirm_password} setvalue={setconfirm_password}/>
                </Col>
        </Row>
        <div className='filter_btns'>
            <button onClick={changePassword} className='save_btn'>{t("change_password")}</button>
        </div>
        </div>
    </div></div>
  )
}
