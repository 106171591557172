
'use client';
import React, { FC, useEffect } from 'react'
import empty_img from '../../images/empty_img.png'

import { motion } from "framer-motion"
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ImageBox from '../../components/ImageBox';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/Store';
import { Helmet } from 'react-helmet-async';


function Selectors() {
     const {t}=useTranslation()

     const [searchParams, setSearchParams] = useSearchParams();
     const big_category = Number(searchParams.get('big_category'))
     const category = Number(searchParams.get('category'))
     const router = useNavigate()
     const {house_type_data, credit_type_data, cmtype_data, house_product_type_data, car_product_type_data, car_service_types_data, house_service_types_data}=useStore()
    useEffect(()=>{
        if(isNaN(big_category) || big_category>2 || big_category<=0 || isNaN(category) || category>8 || category<=0){
          router('/')
        }
    }, [big_category, router])
  return (
     <div  className='sel_box'>
        
      <motion.div initial={{y:100, opacity:0}}  animate={{ y: 0, opacity:1 }}
    transition={{ ease: "easeOut", duration: 0.5 }}>
        
            {category===1?house_type_data.map((item, key)=>{
                 return( <div className='sel_col'><Link to={`/products?big_category=1&category=${category}&house_type__in=[${item.id}]`} className='sel_cart' key={key}> 
                 <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
                 <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
            }):category===2?house_service_types_data.map((item, key)=>{
                return( <div className='sel_col'><Link to={`/products?big_category=1&category=${category}&service_types__in=[${item.id}]`} className='sel_cart' key={key}> 
                <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
                <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
           }):category===3?house_product_type_data.map((item, key)=>{
            return( <div className='sel_col'><Link to={`/products?big_category=1&category=${category}&product_type__in=${item.id}`} className='sel_cart' key={key}> 
            <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
            <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
       }):category===4?credit_type_data.map((item, key)=>{
        return( <div className='sel_col'><Link to={`/products?big_category=1&category=${category}&itype__in=[${item.id}]`} className='sel_cart' key={key}> 
        <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
        <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
   }):category===5?cmtype_data.map((item, key)=>{
    return( <div className='sel_col'><Link to={`/products?big_category=2&category=${category}&cmtype=${item.id}`} className='sel_cart' key={key}> 
    <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
    <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
}):category===6?car_service_types_data.map((item, key)=>{
    return( <div className='sel_col'><Link to={`/products?big_category=2&category=${category}&service_types__in=[${item.id}]`} className='sel_cart' key={key}> 
    <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
    <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
}):category===7?car_product_type_data.map((item, key)=>{
    return( <div className='sel_col'><Link to={`/products?big_category=2&category=${category}&product_type__in=${item.id}`} className='sel_cart' key={key}> 
    <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
    <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
}):category===8?credit_type_data.map((item, key)=>{
    return( <div className='sel_col'><Link to={`/products?big_category=2&category=${category}&itype__in=[${item.id}]`} className='sel_cart' key={key}> 
    <div className='sel_cart_img'><ImageBox alt='image' src={item.image?item.image:empty_img} /></div>
    <div className='sel_cart_text'>{item[`name_${t("lang")==='ru'?'ru':'uz'}`]}</div></Link></div>)
}):<></>}
         </motion.div>
  </div>
 
  )
}
export default Selectors
