import { TypeObject } from "../store/Store";

  
  type Categories = {
    [key: string]: TypeObject[];
  };
  type Label = {
    [key: string]: string;
  };
export type ImageType={
    id:number, image:string
}
export const closeSelect=(str:string)=>{
    var b=document.querySelector(`.select_active`)
        
        if(b){
          b.classList.remove('select_active');
            return(!b.classList.contains(str))
         }else{
            return(true)
         }
}
export const bolData=[null, true, false]
export const categories: Categories={
    '1':[
        {id:1, name_ru:"Размещение", name_uz:"Turar joy"},
        {id:2, name_ru:"Мастер сервис", name_uz:"Usta xizmati"},
        {id:3, name_ru:"Товары для дома", name_uz:"Uy-ro'zg'or buyumlari"},
        {id:4, name_ru:"Ипотека", name_uz:"Ipoteka"},
    ],
    '2':[
        {id:5, name_ru:"Автотранспорт", name_uz:"Avtotransport"},
        {id:6, name_ru:"Мастер сервис", name_uz:"Usta xizmati"},
        {id:7, name_ru:"Запчасти", name_uz:"Ehtiyot qismlar"},
        {id:8, name_ru:"Авто кредит", name_uz:"Avtokredit"},
    ]
}







export const gender_data=[
    {id:1, name_ru:"Мужчина", name_uz:"Erkak"},
        {id:2, name_ru:"Женщина", name_uz:"Ayol"},
]
export const gender_data_all=[
    {id:0, name_ru:"Все", name_uz:"Barchasi"},
    {id:1, name_ru:"Мужчина", name_uz:"Erkak"},
        {id:2, name_ru:"Женщина", name_uz:"Ayol"},
]
export const sort_data=[
    null,
    'price_uzs',
    '-price_uzs',
    'created_at',
    '-created_at',
]
export const products: Categories={
    '1':[
        {id:1, name_ru:"Kop qavatli uylar", name_uz:"Kop qavatli uylar"},
        {id:2, name_ru:"Yangi uylar", name_uz:"Yangi uylar"},
        {id:3, name_ru:"No Turar joylar", name_uz:"No Turar joylar"},
        {id:4, name_ru:"Qurilishi tugalanmagan binolar", name_uz:"Qurilishi tugalanmagan binolar"},
        {id:5, name_ru:"Kotejlar ", name_uz:"Kotejlar "},
        {id:6, name_ru:"Quruq yer", name_uz:"Quruq yer"},
        {id:7, name_ru:"Ipoteka uylar", name_uz:"Ipoteka uylar"},
        {id:8, name_ru:"Arenda uy", name_uz:"Arenda uy"},
        {id:9, name_ru:"Arenda noTurar joylar", name_uz:"Arenda noTurar joylar"},
    ],
    '2':[
        {id:10, name_ru:"Dezayner va prarab xizmati", name_uz:"Dezayner va prarab xizmati"},
        {id:11, name_ru:"Ustalar", name_uz:"Ustalar"},
        {id:12, name_ru:"Qurulish mollari", name_uz:"Qurulish mollari"},
        {id:13, name_ru:"Mebelchilar", name_uz:"Mebelchilar"},
        {id:14, name_ru:"Santexnika xizmati", name_uz:"Santexnika xizmati"},
        {id:15, name_ru:"Elektirik xizmati", name_uz:"Elektirik xizmati"},
        {id:16, name_ru:"Sivarchi xizmati", name_uz:"Sivarchi xizmati"},
        {id:17, name_ru:"Eshik romlar", name_uz:"Eshik romlar"},
        {id:18, name_ru:"Landshaf va bogdorchilik", name_uz:"Landshaf va bogdorchilik"},
        {id:19, name_ru:"Gul va kochatlar", name_uz:"Gul va kochatlar"},
        {id:20, name_ru:"Parda va qilamlar", name_uz:"Parda va qilamlar"},
        {id:21, name_ru:"Kameea qoyish", name_uz:"Kameea qoyish"},
        {id:22, name_ru:"Bitovoy texnika va idish tovoq", name_uz:"Bitovoy texnika va idish tovoq"},
        {id:23, name_ru:"Tadbirlada bezaklar", name_uz:"Tadbirlada bezaklar"},
    ],
    '4':[
        {id:1, name_ru:"Differensial", name_uz:"Differensial"},
        {id:2, name_ru:"Anuintet", name_uz:"Anuintet"},
    ]
}

export const labelProduct: Label={
    '1':"Turar joy turi",
    '2':"Xizmat turi",
    '3':"Homashyo turi",
    '4':"Kredit turi",
    '5':"Avtotransport turi",
    '6':"Xizmat turi",
    '7':"Ehtiyot qismlar turi",
    '8':"Kredit turi",
}





















