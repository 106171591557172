
'use client';
import React, { FC, useEffect } from 'react'
import house from '../../images/icons/house.png'
import car1 from '../../images/icons/car1.png'
import credit from '../../images/icons/credit.png'
import home_master from '../../images/icons/home_master.png'
import car_master from '../../images/icons/car_master.png'
import home_material from '../../images/icons/home_material.png'
import car_material from '../../images/icons/car_material.png'
import { motion } from "framer-motion"
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ImageBox from '../../components/ImageBox';
import { useTranslation } from 'react-i18next';


function Category() {
     const {t}=useTranslation()

     const [searchParams, setSearchParams] = useSearchParams();
     const big_category = Number(searchParams.get('big_category'))
     const router = useNavigate()
    useEffect(()=>{
     if(isNaN(big_category) || big_category>2){
          router('/')
     }
    }, [big_category, router])
  return (
     <div  className='big_box'>
   
   <motion.div initial={{y:100, opacity:0}}  animate={{ y: 0, opacity:1 }}
    transition={{ ease: "easeOut", duration: 0.5 }}>
     {big_category===1?<> <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=1`} className='big_cart'>
    <ImageBox alt='image' src={house} />
         <div className='big_cart_text'>
          {t("turar_joy")}
         </div>
    </Link></div>
    <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=2`} className='big_cart'>
 
    <ImageBox alt='image' src={home_master} />
         <div className='big_cart_text'>
         {t("master_service")}
         </div>
    </Link></div>
    <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=3`} className='big_cart'>
    <ImageBox alt='image' src={home_material} />
         <div className='big_cart_text'>
         {t("material")}
         </div>
    </Link></div>
    <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=4`} className='big_cart'>
    <ImageBox alt='image' src={credit} />
         <div className='big_cart_text'>
         {t("ipoteka")}
         </div>
    </Link></div></>:
    <>
     <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=5`} className='big_cart'>
    <ImageBox alt='image' src={car1} />
         <div className='big_cart_text'>
         {t("cars")}
         </div>
    </Link></div>
    <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=6`} className='big_cart'>
    <ImageBox alt='image' src={car_master} />
         <div className='big_cart_text'>
         {t("master_service")}
         </div>
    </Link></div>
    <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=7`} className='big_cart'>
    <ImageBox alt='image' src={car_material} />
         <div className='big_cart_text'>
         {t("material_car")}
         </div>
    </Link></div>
    <div className='big_col'><Link to={`/selectors?big_category=${big_category}&category=8`} className='big_cart'>
    <ImageBox alt='image' src={credit} />
         <div className='big_cart_text'>
         {t("auto_credit")}
         </div>
    </Link></div></>}
   
    
  </motion.div>
  </div>
 
  )
}
export default Category
