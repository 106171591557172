'use client';
import React, { useState } from 'react'
// import Filters from '../../components/Filters';
import { closeSelect, gender_data_all, sort_data } from '../../components/Data';
import CardHome from '../../components/cards/main_cards/CardHome';
import CardHomeMini from '../../components/cards/main_cards/CardHomeMini';

import { useEffect, useRef } from 'react'
import {Row, Col} from 'antd'
import { VscSettings } from "react-icons/vsc";
import SimpleSelect from '../../components/SimpleSelect';
import { bolData, categories, labelProduct, products } from '../../components/Data';
import SimpleInput from '../../components/SimpleInput';
import MultipleSelect from '../../components/MultipleSelect';
import { IoIosArrowBack } from 'react-icons/io';
import { TbCategory, TbLayoutList } from 'react-icons/tb';
import {motion} from 'framer-motion'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CarObject, CreditObject, HomeObject, ProductObject, ServiceObject, TypeObject, useStore } from '../../store/Store';
import { IoCloseSharp } from 'react-icons/io5';
import SearchBox from '../../components/SearchBox';
import { httpGetRequest } from '../../host/Host';
import { useTranslation } from 'react-i18next';
import { checkArrayData } from '../../components/Alert';
import { ClipLoader } from 'react-spinners';
import CardHomeService from '../../components/cards/main_cards/CardHomeService';
import CardHomeServiceMini from '../../components/cards/main_cards/CardHomeServiceMini';
import CardHomeProduct from '../../components/cards/main_cards/CardHomeProduct';
import CardHomeProductMini from '../../components/cards/main_cards/CardHomeProductMini';
import CardHomeCredit from '../../components/cards/main_cards/CardHomeCredit';
import CardHomeCreditMini from '../../components/cards/main_cards/CardHomeCreditMini';
import CardCarMini from '../../components/cards/main_cards/CardCarMini';
import CardCar from '../../components/cards/main_cards/CardCar';
import CardCarService from '../../components/cards/main_cards/CardCarService';
import CardCarServiceMini from '../../components/cards/main_cards/CardCarServiceMini';
import CardCarProduct from '../../components/cards/main_cards/CardCarProduct';
import CardCarProductMini from '../../components/cards/main_cards/CardCarProductMini';
import CardCarCredit from '../../components/cards/main_cards/CardCarCredit';
import CardCarCreditMini from '../../components/cards/main_cards/CardCarCreditMini';
import { Helmet } from 'react-helmet-async';
export default function Products() {

  
  const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
      if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
        // Handle the body click here
        closeSelect("a");
      }
     }
     const [searchParams, setSearchParams] = useSearchParams();
     const [big_category, setbig_category]=useState(Number(searchParams.get('big_category')))
 
     const [big_home_data, setbig_home_data]=useState<HomeObject[]>([])
     const [big_car_data, setbig_car_data]=useState<CarObject[]>([])
     const [big_home_credit_data, setbig_home_credit_data]=useState<CreditObject[]>([])
     const [big_car_credit_data, setbig_car_credit_data]=useState<CreditObject[]>([])
     const [big_home_product_data, setbig_home_product_data]=useState<ProductObject[]>([])
     const [big_car_product_data, setbig_car_product_data]=useState<ProductObject[]>([])
     const [big_home_service_data, setbig_home_service_data]=useState<ServiceObject[]>([])
     const [big_car_service_data, setbig_car_service_data]=useState<ServiceObject[]>([])
     const [category_old, setcategory_old]=useState(Number(searchParams.get('category')))
     const [sorter_select, setsorter_select]=useState(Number(searchParams.get('sorter_select')))
     const [makler, setmakler]=useState(searchParams.get('makler')!=='null'?searchParams.get('makler'):null)
     const [repair__in, setrepair__in]=useState(searchParams.get('repair__in')!=='null'?searchParams.get('repair__in'):null)
     const [mat_type__in, setmat_type__in]=useState(searchParams.get('mat_type__in')!=='null'?searchParams.get('mat_type__in'):null)
     const [near__in, setnear__in]=useState(searchParams.get('near__in')!=='null'?searchParams.get('near__in'):null)
     const [house_type__in, sethouse_type__in]=useState(searchParams.get('house_type__in')!=='null'?searchParams.get('house_type__in'):null)
     const [b_type__in, setb_type__in]=useState(searchParams.get('b_type__in')!=='null'?searchParams.get('b_type__in'):null)
     const [itype__in, setitype__in]=useState(searchParams.get('itype__in')!=='null'?searchParams.get('itype__in'):null)
     const [service_types__in, setservice_types__in]=useState(searchParams.get('service_types__in')!=='null'?searchParams.get('service_types__in'):null)
     const [product_type__in, setproduct_type__in]=useState(Number(searchParams.get('product_type__in')))
     const [subproduct_type__in, setsubproduct_type__in]=useState(searchParams.get('subproduct_type__in')!=='null'?searchParams.get('subproduct_type__in'):null)
     const [price__gte, setprice__gte]=useState(Number(searchParams.get('price__gte'))!==0?Number(searchParams.get('price__gte')):'')
     const [price__lte, setprice__lte]=useState(Number(searchParams.get('price__lte'))!==0?Number(searchParams.get('price__lte')):'')
     const [probeg__gte, setprobeg__gte]=useState(Number(searchParams.get('probeg__gte'))!==0?Number(searchParams.get('probeg__gte')):'')
     const [probeg__lte, setprobeg__lte]=useState(Number(searchParams.get('probeg__lte'))!==0?Number(searchParams.get('probeg__lte')):'')
    
     const [summ, setsumm]=useState(Number(searchParams.get('summ'))!==0?Number(searchParams.get('summ')):'')
    const [bonus_month__ggte, setbonus_month__ggte]=useState(Number(searchParams.get('bonus_month__ggte'))!==0?Number(searchParams.get('bonus_month__ggte')):'')
     const [bonus_month__lte, setbonus_month__lte]=useState(Number(searchParams.get('bonus_month__lte'))!==0?Number(searchParams.get('bonus_month__lte')):'')
     const [percent, setpercent]=useState(Number(searchParams.get('percent'))!==0?Number(searchParams.get('percent')):'')
     const [init_pay_percent, setinit_pay_percent]=useState(Number(searchParams.get('init_pay_percent'))!==0?Number(searchParams.get('init_pay_percent')):'')
     const [rooms__gte, setrooms__gte]=useState(Number(searchParams.get('rooms__gte'))!==0?Number(searchParams.get('rooms__gte')):'')
     const [floor__gte, setfloor__gte]=useState(Number(searchParams.get('floor__gte'))!==0?Number(searchParams.get('floor__gte')):'')
     const [floor__lte, setfloor__lte]=useState(Number(searchParams.get('floor__lte'))!==0?Number(searchParams.get('floor__lte')):'')
     const [year__gte, setyear__gte]=useState(Number(searchParams.get('year__gte'))!==0?Number(searchParams.get('year__gte')):'')
     const [year__lte, setyear__lte]=useState(Number(searchParams.get('year__lte'))!==0?Number(searchParams.get('year__lte')):'')
     const [month, setmonth]=useState(Number(searchParams.get('month'))!==0?Number(searchParams.get('month')):'')
     const [experience__gte, setexperience__gte]=useState(Number(searchParams.get('experience__gte'))!==0?Number(searchParams.get('experience__gte')):'')
     const [experience__lte, setexperience__lte]=useState(Number(searchParams.get('experience__lte'))!==0?Number(searchParams.get('experience__lte')):'')
     const [rooms__lte, setrooms__lte]=useState(Number(searchParams.get('rooms__lte'))!==0?Number(searchParams.get('rooms__lte')):'')
     const [surface__gte, setsurface__gte]=useState(Number(searchParams.get('surface__gte'))!==0?Number(searchParams.get('surface__gte')):'')
     const [surface__lte, setsurface__lte]=useState(Number(searchParams.get('surface__lte'))!==0?Number(searchParams.get('surface__lte')):'')
     const [region, setregion]=useState(Number(searchParams.get('region')))
     const [ctype, setctype]=useState(Number(searchParams.get('ctype')))
     const [cmtype, setcmtype]=useState(Number(searchParams.get('cmtype')))
     const [model, setmodel]=useState(Number(searchParams.get('model')))
     const [position__in, setposition__in]=useState(searchParams.get('position__in')!=='null'?searchParams.get('position__in'):null)
     const [kuzov__in, setkuzov__in]=useState(searchParams.get('kuzov__in')!=='null'?searchParams.get('kuzov__in'):null)
     const [transmission__in, settransmission__in]=useState(searchParams.get('transmission__in')!=='null'?searchParams.get('transmission__in'):null)
     const [drive_unit__in, setdrive_unit__in]=useState(searchParams.get('drive_unit__in')!=='null'?searchParams.get('drive_unit__in'):null)
     const [color__in, setcolor__in]=useState(searchParams.get('color__in')!=='null'?searchParams.get('color__in'):null)
     const [color_state__in, setcolor_state__in]=useState(searchParams.get('color_state__in')!=='null'?searchParams.get('color_state__in'):null)
     const [engine_type__in, setengine_type__in]=useState(searchParams.get('engine_type__in')!=='null'?searchParams.get('engine_type__in'):null)
     const [district__in, setdistrict__in]=useState(searchParams.get('district__in')!=='null'?searchParams.get('district__in'):null)
     const [furnished, setfurnished]=useState(searchParams.get('furnished')!=='null'?searchParams.get('furnished'):null)
     const [autosalon, setautosalon]=useState(searchParams.get('autosalon')!=='null'?searchParams.get('autosalon'):null)
     const [rent, setrent]=useState(searchParams.get('rent')!=='null'?searchParams.get('rent'):null)
     const [long_term_pay, setlong_term_pay]=useState(searchParams.get('long_term_pay')!=='null'?searchParams.get('long_term_pay'):null)
     const [gender, setgender]=useState(Number(searchParams.get('gender')))
     const [model_data, setmodel_data]=useState<TypeObject[]>([])
    const [position_data, setposition_data]=useState<TypeObject[]>([])
    const [house_subproduct_type_data, sethouse_subproduct_type_data]=useState<TypeObject[]>([])
    const [car_subproduct_type_data, setcar_subproduct_type_data]=useState<TypeObject[]>([])
     const [open_filter, setopen_filter]=useState(window.innerWidth>=1024?true:false)
     const {region_data, setloader,district_data, setcategory, setdistrict_data, b_type_data,
          mat_type_data, repair_data, house_type_data, shape, money_type,setshape,house_service_types_data,
          category,car_service_types_data,car_product_type_data, house_product_type_data,credit_type_data,
          kuzov_data,
          search, setsearch,
      engine_type_data,setctype_data,
      transmission_data,
      drive_unit_data,
      color_data,
      color_state_data,
      ctype_data,
      cmtype_data,
           setmoney_type, near_data}=useStore()
           const elementRef = useRef<HTMLDivElement>(null);
    
     const [page, setpage]=useState(1)
     const [next, setnext]=useState('1')
     const [axios_send, setaxios_send]=useState(0)
     const [number, setnumber]=useState(0)
     const [mini_loader, setmini_loader]=useState(true)
     const navigate=useNavigate()
     const {t} =useTranslation()
      const router = useNavigate()
 
      useEffect(()=>{
         
         if(isNaN(big_category) || big_category>2 || big_category<=0 || isNaN(category_old) || category_old>8 || category_old<=0){
             router('/')
         }else{
            if(category_old<=4){
                setbig_category(1)
            }else{
                setbig_category(2)
            }
            getDistricts()
            getcarproduct_type()
            gethomeproduct_type()
                getPositions()
                getMarka()
                getModels()
                setcategory(category_old)
                if(category_old===1){
                    setbig_home_data([])
                    getHomeData('1', 1)
                }
                if(category_old===2){
                    setbig_home_service_data([])
                    getHomeServiceData('1', 1)
                }
                if(category_old===6){
                    setbig_car_service_data([])
                    getCarServiceData('1', 1)
                }
                if(category_old===3){
                    setbig_home_product_data([])
                    getHomeProductData('1', 1)
                }
                if(category_old===7){
                    setbig_car_product_data([])
                    getCarProductData('1', 1)
                }
                if(category_old===4){
                    setbig_home_credit_data([])
                    getHomeCreditData('1', 1)
                }
                if(category_old===8){
                    setbig_car_credit_data([])
                    getCarCreditData('1', 1)
                }
                if(category_old===5){
                    setbig_car_data([])
                    getCarData('1', 1)
                }
         }
        
      }, [sorter_select])
      
      const getDistricts=async()=>{
        if(region!==0){
        var res=await httpGetRequest('/districts/?region='+region)
        setdistrict_data(res.data)
         } }
         const getModels=async()=>{
          if(ctype!==0){
              try{
                  var res=await httpGetRequest('/cars/models/', {ctype:ctype})
                  setmodel_data(res.data)
                  setloader(false)
                }catch(err){
                  setloader(false)
                }
           } }
           const getMarka=async()=>{
            if(cmtype!==0){
                try{
                    var res=await httpGetRequest('/cars/ctypes/', {cmtype:cmtype})
                    setctype_data(res.data)
                    setloader(false)
                  }catch(err){
                    setloader(false)
                  }
             } }
             const getPositions=async()=>{
                if(model!==0){
                    try{
                        var res=await httpGetRequest('/cars/positions/', {cmodel:model})
                        setposition_data(res.data)
                        setloader(false)
                      }catch(err){
                        setloader(false)
                      }
                 } }
      
     const searchData=()=>{
        if(window.innerWidth<=1024){
            setopen_filter(false)
        }
       if(category_old===1){
            navigate(`/products?search=${search}&big_category=1&category=1&makler=${checkArrayData(makler)}&furnished=${checkArrayData(furnished)}&price__gte=${price__gte}&price__lte=${price__lte}&floor__gte=${floor__gte}&floor__lte=${floor__lte}&rooms__gte=${rooms__gte}&rooms__lte=${rooms__lte}&year__gte=${year__gte}&year__lte=${year__lte}&surface__gte=${surface__gte}&surface__lte=${surface__lte}&house_type__in=${checkArrayData(house_type__in)}&mat_type__in=${checkArrayData(mat_type__in)}&b_type__in=${checkArrayData(b_type__in)}&region=${region}&district__in=${checkArrayData(district__in)}&repair__in=${checkArrayData(repair__in)}&near__in=${checkArrayData(near__in)}`)
            setbig_home_data([])
            getHomeData('1', 1)
            setcategory(1)
        }
        if(category_old===5){
            navigate(`/products?search=${search}&big_category=2&category=5&autosalon=${checkArrayData(autosalon)}&rent=${checkArrayData(rent)}&long_term_pay=${checkArrayData(long_term_pay)}&price__gte=${price__gte}&price__lte=${price__lte}&probeg__gte=${probeg__gte}&probeg__lte=${probeg__lte}&year__gte=${year__gte}&year__lte=${year__lte}&cmtype=${cmtype}&ctype=${ctype}&model=${model}&position__in=${checkArrayData(position__in)}&b_type__in=${checkArrayData(b_type__in)}&region=${region}&district__in=${checkArrayData(district__in)}&kuzov__in=${checkArrayData(kuzov__in)}&transmission__in=${checkArrayData(transmission__in)}&drive_unit__in=${checkArrayData(drive_unit__in)}&color__in=${checkArrayData(color__in)}&color_state__in=${checkArrayData(color_state__in)}&engine_type__in=${checkArrayData(engine_type__in)}`)
            setbig_car_data([])
            getCarData('1', 1)
            setcategory(5)
        }
        if(category_old===2){
            navigate(`/products?search=${search}&big_category=1&category=2&experience__gte=${experience__gte}&experience__lte=${experience__lte}&gender=${gender}&service_types__in=${checkArrayData(service_types__in)}&region=${region}&district__in=${checkArrayData(district__in)}`)
            setbig_home_service_data([])
            getHomeServiceData('1', 1)
            setcategory(2)
        }
        if(category_old===6){
            navigate(`/products?search=${search}&big_category=2&category=6&experience__gte=${experience__gte}&experience__lte=${experience__lte}&gender=${gender}&service_types__in=${checkArrayData(service_types__in)}&region=${region}&district__in=${checkArrayData(district__in)}`)
            setbig_car_service_data([])
            getCarServiceData('1', 1)
            setcategory(6)
        }
        if(category_old===3){
            navigate(`/products?search=${search}&big_category=1&category=3&region=${region}&district__in=${checkArrayData(district__in)}&price__gte=${price__gte}&price__lte=${price__lte}&product_type__in=${product_type__in}&subproduct_type__in=${checkArrayData(subproduct_type__in)}`)
            setbig_home_product_data([])
            getHomeProductData('1', 1)
            setcategory(3)
        }
        if(category_old===7){
            navigate(`/products?search=${search}&big_category=2&category=7&region=${region}&district__in=${checkArrayData(district__in)}&price__gte=${price__gte}&price__lte=${price__lte}&product_type__in=${product_type__in}&subproduct_type__in=${checkArrayData(subproduct_type__in)}`)
            setbig_car_product_data([])
            getCarProductData('1', 1)
            setcategory(7)
        }
        if(category_old===4){
            navigate(`/products?search=${search}&big_category=1&category=4&region=${region}&district__in=${checkArrayData(district__in)}&summ=${summ}&month=${month}&bonus_month__ggte=${bonus_month__ggte}&bonus_month__lte=${bonus_month__lte}&init_pay_percent=${init_pay_percent}&percent=${percent}&itype__in=${checkArrayData(itype__in)}&b_type__in=${checkArrayData(b_type__in)}`)
            setbig_home_service_data([])
            getHomeCreditData('1', 1)
            setcategory(4)
        }
        if(category_old===8){
            navigate(`/products?search=${search}&big_category=2&category=8&region=${region}&district__in=${checkArrayData(district__in)}&summ=${summ}&month=${month}&bonus_month__ggte=${bonus_month__ggte}&bonus_month__lte=${bonus_month__lte}&init_pay_percent=${init_pay_percent}&percent=${percent}&itype__in=${checkArrayData(itype__in)}&b_type__in=${checkArrayData(b_type__in)}`)
            setbig_car_service_data([])
            getCarCreditData('1', 1)
            setcategory(8)
        }
     }
     const restoreFilterData=()=>{
 setmakler(null)

 setfurnished(null)
 setautosalon(null)
 setrent(null)
 setlong_term_pay(null)
 setprice__gte('')
 setprice__lte('')
 setfloor__gte('')
 setfloor__lte('')
 setrooms__gte('')
 setrooms__lte('')
 setyear__gte('')
 setsorter_select(0)
 setyear__lte('')
 setsurface__gte('')
 setsurface__lte('')
 setprobeg__gte('')
 setprobeg__lte('')
 sethouse_type__in(null)
 setgender(0)
 setregion(0)
 setctype(0)
 setcmtype(0)
 setmodel(0)
 setposition__in(null)
 setkuzov__in(null)
 settransmission__in(null)
 setdrive_unit__in(null)
 setcolor__in(null)
 setcolor_state__in(null)
 setengine_type__in(null)
 setmat_type__in(null)
 setdistrict__in(null)
 setb_type__in(null)
 setitype__in(null)
 setproduct_type__in(0)
 setsubproduct_type__in(null)
 sethouse_subproduct_type_data([])
 setcar_subproduct_type_data([])
 setmodel_data([])
 setctype_data([])
 setposition_data([])
 setservice_types__in(null)
 setrepair__in(null)
 setnear__in(null)
 setpage(1)
 setnext('1')
 setnumber(number+1)
     }

     const restoreHome=()=>{
      if(category===1){
          navigate(`/products?big_category=1&category=1`)
          getHomeData('1', 1, {page:1})
       }
       if(category===5){
          navigate(`/products?big_category=2&category=5`)
          getCarData('1', 1, {page:1})
       }
       if(category===2){
          navigate(`/products?big_category=1&category=2`)
          getHomeServiceData('1', 1, {page:1})
       }
       if(category===6){
          navigate(`/products?big_category=2&category=6`)
          getCarServiceData('1', 1, {page:1})
       }
       if(category===3){
          navigate(`/products?big_category=1&category=3`)
          getHomeProductData('1', 1, {page:1})
       }
       if(category===7){
          navigate(`/products?big_category=2&category=7`)
          getCarProductData('1', 1, {page:1})
       }
       if(category===4){
          navigate(`/products?big_category=1&category=4`)
          getHomeCreditData('1', 1, {page:1})
       }
       if(category===8){
          navigate(`/products?big_category=2&category=8`)
          getCarCreditData('1', 1, {page:1})
       }


   }
   const searchHome=()=>{
    
    if(category===1){
        navigate(`/products?big_category=1&category=1&search=${search}`)
        getHomeData('1', 1, {page:1})
     }
     if(category===5){
        navigate(`/products?big_category=2&category=5&search=${search}`)
        getCarData('1', 1, {page:1})
     }
     if(category===2){
        navigate(`/products?big_category=1&category=2&search=${search}`)
        getHomeServiceData('1', 1, {page:1})
     }
     if(category===6){
        navigate(`/products?big_category=2&category=6&search=${search}`)
        getCarServiceData('1', 1, {page:1})
     }
     if(category===3){
        navigate(`/products?big_category=1&category=3&search=${search}`)
        getHomeProductData('1', 1, {page:1})
     }
     if(category===7){
        navigate(`/products?big_category=2&category=7&search=${search}`)
        getCarProductData('1', 1, {page:1})
     }
     if(category===4){
        navigate(`/products?big_category=1&category=4&search=${search}`)
        getHomeCreditData('1', 1, {page:1})
     }
     if(category===8){
        navigate(`/products?big_category=2&category=8&search=${search}`)
        getCarCreditData('1', 1, {page:1})
     }


 }
 
     const getHomeData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
               name__icontains:search,
                makler:null,
                furnished:null,
                price_uzs__gte:null,
                price_uzs__lte:null,
                price_usd__gte:null,
                price_usd__lte:null,
                floor__gte:null,
                floor__lte:null,
                rooms__gte:null,
                rooms__lte:null,
                year__gte:null,
                year__lte:null,
                surface__gte:null,
                surface__lte:null, 
                house_type__in:null,
                mat_type__in:null,
                b_type__in:null,
                repair__in:null,
                near__in:null,
                page:page_data,
                ordering:null,
                status:1,
            }  
        }else{
            config={
               
                makler:makler!=null && JSON.parse(makler).length!==2?makler.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(makler.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                furnished:furnished!=null && JSON.parse(furnished).length!==2?furnished.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(furnished.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                price_uzs__gte:money_type===1?String(price__gte).length===0?null:String(price__gte):null,
                price_uzs__lte:money_type===1?String(price__lte).length===0?null:String(price__lte):null,
                price_usd__gte:money_type===2?String(price__gte).length===0?null:String(price__gte):null,
                price_usd__lte:money_type===2?String(price__lte).length===0?null:String(price__lte):null,
                floor__gte:String(floor__gte).length===0?null:String(floor__gte),
                floor__lte:String(floor__lte).length===0?null:String(floor__lte),
                rooms__gte:String(rooms__gte).length===0?null:String(rooms__gte),
                ordering:sorter_select!==null?sort_data[sorter_select]:null,
                rooms__lte:String(rooms__lte).length===0?null:String(rooms__lte),
                year__gte:String(year__gte).length===0?null:String(year__gte),
                year__lte:String(year__lte).length===0?null:String(year__lte),
                surface__gte:String(surface__gte).length===0?null:String(surface__gte),
                surface__lte:String(surface__lte).length===0?null:String(surface__lte), 
                house_type__in:house_type__in!=null?house_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?house_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                mat_type__in:mat_type__in!=null?mat_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?mat_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                b_type__in:b_type__in!=null?b_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?b_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                repair__in:repair__in!=null?repair__in.replaceAll("[", '').replaceAll("]", '').length!==0?repair__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                near__in:near__in!=null?near__in.replaceAll("[", '').replaceAll("]", '').length!==0?near__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                page:page_data,
                region__in:String(region).length===0 || region===0?null:String(region),
                status:1
            }
        }
         
         try{
            var res=await httpGetRequest('/houses/', config)
            
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_home_data, ...res.data.results]
            }
            setbig_home_data(a)
            setnext(res.data.next)
            
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }
    const getCarData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                region__in:null,
                district__in:null,
                b_type__in:null,
                autosalon:null,
                rent:null,
                long_term_pay:null,
                price_uzs__gte:null,
                price_uzs__lte:null,
                price_usd__gte:null,
                price_usd__lte:null,
                probeg__gte:null,
                probeg__lte:null,
                year__gte:null,
                year__lte:null,
                ctype__in:null,
                cmtype__in:null,
                model__in:null,
                position__in:null,
                kuzov__in:null,
                transmission__in:null,
                drive_unit__in:null,
                color__in:null,
                color_state__in:null,
                engine_type__in:null,
                page:page_data,
                ordering:null,
                status:1,
            }  
        }else{
            config={
                
                region__in:String(region).length===0 || region===0?null:String(region),
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                b_type__in:b_type__in!=null?b_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?b_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                autosalon:autosalon!=null && JSON.parse(autosalon).length!==2?autosalon.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(autosalon.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                rent:rent!=null && JSON.parse(rent).length!==2?rent.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(rent.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                long_term_pay:long_term_pay!=null && JSON.parse(long_term_pay).length!==2?long_term_pay.replaceAll("[", '').replaceAll("]", '').length!==0?bolData[Number(long_term_pay.replaceAll("[", '').replaceAll("]", ''))]:null:null,
                price_uzs__gte:money_type===1?String(price__gte).length===0?null:String(price__gte):null,
                price_uzs__lte:money_type===1?String(price__lte).length===0?null:String(price__lte):null,
                price_usd__gte:money_type===2?String(price__gte).length===0?null:String(price__gte):null,
                price_usd__lte:money_type===2?String(price__lte).length===0?null:String(price__lte):null,
                probeg__gte:String(probeg__gte).length===0?null:String(probeg__gte),
                probeg__lte:String(probeg__lte).length===0?null:String(probeg__lte),
                year__gte:String(year__gte).length===0?null:String(year__gte),
                year__lte:String(year__lte).length===0?null:String(year__lte),
                ctype__in:String(ctype).length===0 || ctype===0?null:String(ctype),
                cmtype__in:String(cmtype).length===0 || cmtype===0?null:String(cmtype),
                model__in:String(model).length===0 || model===0?null:String(model),
                position__in:position__in!=null?position__in.replaceAll("[", '').replaceAll("]", '').length!==0?position__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                kuzov__in:kuzov__in!=null?kuzov__in.replaceAll("[", '').replaceAll("]", '').length!==0?kuzov__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                transmission__in:transmission__in!=null?transmission__in.replaceAll("[", '').replaceAll("]", '').length!==0?transmission__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                drive_unit__in:drive_unit__in!=null?drive_unit__in.replaceAll("[", '').replaceAll("]", '').length!==0?drive_unit__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                color__in:color__in!=null?color__in.replaceAll("[", '').replaceAll("]", '').length!==0?color__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                color_state__in:color_state__in!=null?color_state__in.replaceAll("[", '').replaceAll("]", '').length!==0?color_state__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                engine_type__in:engine_type__in!=null?engine_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?engine_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                page:page_data,
                ordering:sorter_select!==null?sort_data[sorter_select]:null,
                status:1
            }
        }
         
         try{
            var res=await httpGetRequest('/cars/', config)
            
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_car_data, ...res.data.results]
            }
            setbig_car_data(a)
            setnext(res.data.next)
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }

    const getHomeCreditData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                summ:null,
                percent:null,
                init_pay_percent:null,
                bonus_month__ggte:null,
                bonus_month__lte:null,
                month:null,
                itype__in:null,
                district__in:null,
                b_type__in:null,
                region__in:null,
                page:page_data,
                ordering:null,
                status:1,
            }  
        }else{
            config={
                
                summ:String(summ).length===0?null:String(summ),
                percent:String(percent).length===0?null:String(percent),
                init_pay_percent:String(init_pay_percent).length===0?null:String(init_pay_percent),
                bonus_month__ggte:String(bonus_month__ggte).length===0?null:String(bonus_month__ggte),
                bonus_month__lte:String(bonus_month__lte).length===0?null:String(bonus_month__lte),
                month:String(month).length===0?null:String(month),
                itype__in:itype__in!=null?itype__in.replaceAll("[", '').replaceAll("]", '').length!==0?itype__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                b_type__in:b_type__in!=null?b_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?b_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                region__in:String(region).length===0 || region===0?null:String(region),
                page:page_data,
                status:1
                
            }
        }
         
         try{
            var res=await httpGetRequest('/ipoteka/', config)
            
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_home_credit_data, ...res.data.results]
            }
            setbig_home_credit_data(a)
            setnext(res.data.next)
            
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }

    const getCarCreditData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                summ:null,
                percent:null,
                init_pay_percent:null,
                bonus_month__ggte:null,
                bonus_month__lte:null,
                month:null,
                itype__in:null,
                district__in:null,
                b_type__in:null,
                region__in:null,
                page:page_data,
                ordering:null,
                status:1,
            }  
        }else{
            config={
                
                summ:String(summ).length===0?null:String(summ),
                percent:String(percent).length===0?null:String(percent),
                init_pay_percent:String(init_pay_percent).length===0?null:String(init_pay_percent),
                bonus_month__ggte:String(bonus_month__ggte).length===0?null:String(bonus_month__ggte),
                bonus_month__lte:String(bonus_month__lte).length===0?null:String(bonus_month__lte),
                month:String(month).length===0?null:String(month),
                itype__in:itype__in!=null?itype__in.replaceAll("[", '').replaceAll("]", '').length!==0?itype__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                b_type__in:b_type__in!=null?b_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?b_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                region__in:String(region).length===0 || region===0?null:String(region),
                page:page_data,
                status:1
                
            }
        }
         
         try{
            var res=await httpGetRequest('/auto-credits/', config)
            
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_car_credit_data, ...res.data.results]
            }
            setbig_car_credit_data(a)
            setnext(res.data.next)
            
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }

    const getHomeProductData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                price_uzs__gte:null,
                price_uzs__lte:null,
                price_usd__gte:null,
                price_usd__lte:null,
                product_type__in:null,
                subproduct_type__in:null,
                page:page_data,
                ordering:null,
                status:1,
            }  
        }else{
            config={
                
                price_uzs__gte:money_type===1?String(price__gte).length===0?null:String(price__gte):null,
                price_uzs__lte:money_type===1?String(price__lte).length===0?null:String(price__lte):null,
                price_usd__gte:money_type===2?String(price__gte).length===0?null:String(price__gte):null,
                price_usd__lte:money_type===2?String(price__lte).length===0?null:String(price__lte):null,
                ordering:sorter_select!==null?sort_data[sorter_select]:null,
                product_type__in:String(product_type__in).length===0 || product_type__in===0?null:String(product_type__in),
                subproduct_type__in:subproduct_type__in!=null?subproduct_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?subproduct_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                page:page_data,
                region__in:String(region).length===0 || region===0?null:String(region),
                status:1
            }
        }
         
         try{
            var res=await httpGetRequest('/houses-products/', config)
            
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_home_product_data, ...res.data.results]
            }
            setbig_home_product_data(a)
            setnext(res.data.next)
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }

    const getCarProductData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                price_uzs__gte:null,
                price_uzs__lte:null,
                price_usd__gte:null,
                price_usd__lte:null,
                product_type__in:null,
                subproduct_type__in:null,
                page:page_data,
                ordering:null,
                status:1,
            }  
        }else{
            config={
                
                price_uzs__gte:money_type===1?String(price__gte).length===0?null:String(price__gte):null,
                price_uzs__lte:money_type===1?String(price__lte).length===0?null:String(price__lte):null,
                price_usd__gte:money_type===2?String(price__gte).length===0?null:String(price__gte):null,
                price_usd__lte:money_type===2?String(price__lte).length===0?null:String(price__lte):null,
                ordering:sorter_select!==null?sort_data[sorter_select]:null,
                product_type__in:String(product_type__in).length===0 || product_type__in===0?null:String(product_type__in),
                subproduct_type__in:subproduct_type__in!=null?subproduct_type__in.replaceAll("[", '').replaceAll("]", '').length!==0?subproduct_type__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                page:page_data,
                region__in:String(region).length===0 || region===0?null:String(region),
                status:1
            }
        }
         
         try{
            var res=await httpGetRequest('/cars-products/', config)
            
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_car_product_data, ...res.data.results]
            }
            setbig_car_product_data(a)
            setnext(res.data.next)
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }

    const getHomeServiceData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                age__gte:null,
                age__lte:null,
                experience__gte:null,
                experience__lte:null,
                service_types__in:null,
                district__in:null,
                gender:null,
                ordering:null,
                region__in:null,
                page:page_data,
                status:1,
            }  
        }else{
            config={
                
                experience__gte:String(experience__gte).length===0?null:String(experience__gte),
                experience__lte:String(experience__lte).length===0?null:String(experience__lte),
                service_types__in:service_types__in!=null?service_types__in.replaceAll("[", '').replaceAll("]", '').length!==0?service_types__in.replaceAll("[", '').replaceAll("]", ''):null:null, 
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                gender:gender!==0?String(gender):null,
                region__in:String(region).length===0 || region===0?null:String(region),
                ordering:sorter_select!==null?sort_data[sorter_select]:null,
                page:page_data,
                status:1
            }
        }
         
         try{
            var res=await httpGetRequest('/services/house/', config)
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_home_service_data, ...res.data.results]
            }
            setbig_home_service_data(a)
            setnext(res.data.next)
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }
    const getCarServiceData=async(next_data:string|null|undefined, page_data:number, dat?:{page:number})=>{
        if(next_data!==null && next_data!==undefined && next_data.length!==0){
            setmini_loader(true)
            setaxios_send(1)
            var config={}
        if(dat!==undefined){
            config={
                name__icontains:search,
                age__gte:null,
                age__lte:null,
                experience__gte:null,
                experience__lte:null,
                service_types__in:null,
                district__in:null,
                gender:null,
                ordering:null,
                region__in:null,
                page:page_data,
                status:1,
            }  
        }else{
            config={
                
                experience__gte:String(experience__gte).length===0?null:String(experience__gte),
                experience__lte:String(experience__lte).length===0?null:String(experience__lte),
                service_types__in:service_types__in!=null?service_types__in.replaceAll("[", '').replaceAll("]", '').length!==0?service_types__in.replaceAll("[", '').replaceAll("]", ''):null:null, 
                district__in:district__in!=null?district__in.replaceAll("[", '').replaceAll("]", '').length!==0?district__in.replaceAll("[", '').replaceAll("]", ''):null:null,
                gender:gender!==0?String(gender):null,
                region__in:String(region).length===0 || region===0?null:String(region),
                ordering:sorter_select!==null?sort_data[sorter_select]:null,
                page:page_data,
                status:1
            }
        }
         
         try{
            var res=await httpGetRequest('/services/car/', config)
            if(page_data===1){
             var a=[...res.data.results]
            }else{
             var a=[...big_car_service_data, ...res.data.results]
            }
            setbig_car_service_data(a)
            setnext(res.data.next)
            setpage(page_data+1)
            setaxios_send(0)
            setloader(false)
            setmini_loader(false)
            setnumber(number+1)
         }catch(err){
           
           setaxios_send(0)
           setmini_loader(false)
         }
        }
    }
 
     const handlechange=(id:number)=>{
       return
    }
    const handlebig_category=(id:number)=>{
     restoreFilterData()
     setcategory_old(categories[String(id)][0].id)
     sethouse_type__in(null)
   }
   const handleregion=async(id:number)=>{
    setdistrict__in(null)
    setdistrict_data([])
    var res=await httpGetRequest('/districts/?region='+id)
     setdistrict_data(res.data)

  }
  const gethomeproduct_type=async()=>{
    if(product_type__in!==0 && category===3){
    var res=await httpGetRequest('/houses-products/subtypes/', {product_type:product_type__in})
    sethouse_subproduct_type_data(res.data)
     } }

     const getcarproduct_type=async()=>{
        if(product_type__in!==0 && category===7){
        var res=await httpGetRequest('/car-products/subtypes/', {product_type:product_type__in})
        sethouse_subproduct_type_data(res.data)
         } }

  const handlehomeproduct_type=async(id:number)=>{
    setsubproduct_type__in(null)
    sethouse_subproduct_type_data([])
    if(id!==0){
        var res=await httpGetRequest('/houses-products/subtypes/', {product_type:id})
        sethouse_subproduct_type_data(res.data)
    }
    

  }
  const handlecarproduct_type=async(id:number)=>{
    setsubproduct_type__in(null)
    setcar_subproduct_type_data([])
    if(id!==0){
    var res=await httpGetRequest('/cars-products/subtypes/', {product_type:id})
     setcar_subproduct_type_data(res.data)
    }
  }
   const handleScroll = () => {
        
    if(document.querySelector('.axios_send') && document.querySelector('.axios_send')?.innerHTML==='0'){
        if (!elementRef.current) return;

        const { scrollTop, clientHeight, scrollHeight } = elementRef.current;
        if (scrollTop + clientHeight >= scrollHeight-200) {
            if(category===1){
                getHomeData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
             if(category===5){
                getCarData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
            if(category===2){
                getHomeServiceData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
            if(category===6){
                getCarServiceData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
            if(category===3){
                getHomeProductData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
            if(category===7){
                getCarProductData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
            if(category===4){
                getHomeCreditData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
            if(category===8){
                getCarCreditData(document.querySelector('.next')?.innerHTML, Number(document.querySelector('.page')?.innerHTML))
            }
        }
    }
    
  };
     const handlecategory=(id:number)=>{
       restoreFilterData()    
       
     }
 
     const restoreFilter=()=>{
        restoreFilterData()
       restoreHome()
     }
     const openFilterBox=()=>{
         setopen_filter(true)
         document.body.scrollTop = 0; // For Safari
         document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
     }
     const closeFilterBox=()=>{
         setopen_filter(false)
         document.body.scrollTop = 0; // For Safari
         document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
     }
     const handlecmtype=async(id_new:number)=>{
        
        setmodel_data([])
        setmodel(0)
        setctype_data([])
        setctype(0)
        setposition_data([])
        setposition__in(null)
        if(id_new!==0){
        try{
          var res=await httpGetRequest('/cars/ctypes/', {cmtype:id_new})
          setctype_data(res.data)
          setloader(false)
        }catch(err){
          setloader(false)
        }}else{
            setloader(false)
        }
      }
      const handlectype=async(id_new:number)=>{
        
        setmodel_data([])
        setmodel(0)
        setposition_data([])
        setposition__in(null)
        if(id_new!==0){
        try{
          var res=await httpGetRequest('/cars/models/', {ctype:id_new})
          setmodel_data(res.data)
          setloader(false)
        }catch(err){
          setloader(false)
        }}else{
            setloader(false)
        }
      }
      const handlemodel=async(id_new:number)=>{
       
        setposition_data([])
        setposition__in(null)
        if(id_new!==0){
        try{
          var res=await httpGetRequest('/cars/positions/', {cmodel:id_new})
          setposition_data(res.data)
          setloader(false)
        }catch(err){
          setloader(false)
        }}else{
            setloader(false)
        }
      }
  return (
    <div onClick={(e)=>{handleCloseSelect(e)}}  ref={elementRef} onScroll={handleScroll} className='products_box big_pro_box'>
        <Helmet>
        <title>Kategoriya</title>
        <meta name="description" content="Barcha turgani uy va avtomobil xizmatlarini bizning tizimimiz orqali toping" />
      </Helmet>
     <>
     <motion.div initial={{y:100, opacity:0}}  animate={{ y: 0, opacity:1 }}
    transition={{ ease: "easeOut", duration: 0.5 }} className={`filter_search navbar_search`}>
     <div className='search_box_filter'>
     <SearchBox  search={search} getData={()=>{restoreFilterData(); searchHome()}} setsearch={setsearch}/>
     </div>
     <button onClick={()=>{if(window.innerWidth<=1024){openFilterBox()}}} className='filter_open_btn'><VscSettings /></button>
    </motion.div>
     <motion.div initial={{y:100, opacity:0}}  animate={{ y: 0, opacity:1 }}
    transition={{ ease: "easeOut", duration: 0.5 }} className={`products_filter ${open_filter?"open_filter":''}`}>
          <h1 className='filter_head' onClick={()=>{if(window.innerWidth>=1024){setopen_filter(!open_filter)}}}>
            <p>{t("filters")}</p>
            <span className='comp'><IoIosArrowBack /></span>
            <button className='phone'  onClick={()=>{if(window.innerWidth<=1024){closeFilterBox()}}}><IoCloseSharp /></button>
            </h1>
           <Row className='filter_box'>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handleregion} name="region" label={t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="district__in" label={t("district")} value={district__in} setvalue={setdistrict__in} data={district_data}/>
            </Col>
           <Col className='filter_col no_overflow' xl={6} lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handlebig_category} name="big_category" label={t("toifa")} value={big_category} setvalue={setbig_category} data={[
                    {id:1, name_ru:"Недвижимость", name_uz:"Ko'chmas mulk"},
                    {id:2, name_ru:"Автотранспорт", name_uz:"Avtotransport"},
                ]}/>
            </Col>
            <Col className='filter_col no_overflow' xl={6} lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handlecategory} name="category" label={t("ichki_toifa")} value={category_old} setvalue={setcategory_old} data={categories[String(big_category)]}/>
            </Col>
           
            
            {
              category_old===1?<>
               <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="house_type__in" label={t("house_type")} value={house_type__in} setvalue={sethouse_type__in} data={house_type_data}/>
            </Col>
               <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="bozor" label={t("b_type")} value={b_type__in} setvalue={setb_type__in} data={b_type_data}/>
            </Col>
             <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={money_type===1?t("price_uzs"):t("price_usd")} value={price__gte} setvalue={setprice__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={price__lte} setvalue={setprice__lte}/>
            </Col>
              <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("rooms")} value={rooms__gte} setvalue={setrooms__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={rooms__lte} setvalue={setrooms__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("floor")} value={floor__gte} setvalue={setfloor__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={floor__lte} setvalue={setfloor__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("surface")} value={surface__gte} setvalue={setsurface__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={surface__lte} setvalue={setsurface__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("build_year")} value={year__gte} setvalue={setyear__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={year__lte} setvalue={setyear__lte}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="furnished" label={t("furnished")} value={furnished} setvalue={setfurnished} data={[
                    {id:1, name_ru:"Есть", name_uz:"Bor"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="makler" label={t("makler")} value={makler} setvalue={setmakler} data={[
                    {id:1, name_ru:"Есть", name_uz:"Bor"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="repair__in" label={t("repair")} value={repair__in} setvalue={setrepair__in} data={repair_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="mat_type__in" label={t("building_type")} value={mat_type__in} setvalue={setmat_type__in} data={mat_type_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="near__in" label={t("near")} value={near__in} setvalue={setnear__in} data={near_data}/>
            </Col>
            
              </>:<></>
            }
            {
              category_old===2 || category_old===6?
              <>
              <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
              {category_old===2?
              <MultipleSelect name="service_types__in"  label={t("service_type")} value={service_types__in} setvalue={setservice_types__in} data={house_service_types_data}/>
            :<MultipleSelect name="service_types__in"  label={t("service_type")} value={service_types__in} setvalue={setservice_types__in} data={car_service_types_data}/>
        }
              
            </Col>
              <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
              <SimpleSelect onchange={handlechange} name="gender" label= {t("gender")} value={gender} setvalue={setgender} data={gender_data_all}/>
            
            </Col>
            
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("experience")} value={experience__gte} setvalue={setexperience__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={experience__lte} setvalue={setexperience__lte}/>
            </Col>
              </>
              :<></>
            }
            {
              category_old===3 || category_old===7?<>
              <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
              {category_old===3?
              <SimpleSelect onchange={handlehomeproduct_type} name="product_type__in" label={t("product_type")} value={product_type__in} setvalue={setproduct_type__in} data={[{id:0, name_ru:"Все", name_uz:"Barchasi"},...house_product_type_data]}/>
            :<SimpleSelect onchange={handlecarproduct_type} name="product_type__in" label={t("product_type")} value={product_type__in} setvalue={setproduct_type__in} data={[{id:0, name_ru:"Все", name_uz:"Barchasi"},...car_product_type_data]}/>} 
           </Col>
           <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
           {category_old===3?
              
              <MultipleSelect name="subproduct_type__in"  label={t("subproduct_type")} value={subproduct_type__in} setvalue={setsubproduct_type__in} data={house_subproduct_type_data}/>
            :<MultipleSelect name="subproduct_type__in"  label={t("subproduct_type")} value={subproduct_type__in} setvalue={setsubproduct_type__in} data={car_subproduct_type_data}/>
        }
        </Col>
               <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={money_type===1?t("price_uzs"):t("price_usd")} value={price__gte} setvalue={setprice__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={price__lte} setvalue={setprice__lte}/>
            </Col>
              </>:<></>
            }
            {
              category_old===4 || category_old===8?<>
              <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
        <MultipleSelect name="itype__in" label={t("credit_type")} value={itype__in} setvalue={setitype__in} data={credit_type_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
        <MultipleSelect name="b_type__in" label={t("b_type")} value={b_type__in} setvalue={setb_type__in} data={b_type_data}/>
            </Col>
               <Col className='filter_col filter_col_right' xl={6} lg={8} md={12} sm={24}>
                <SimpleInput placeholder={''} label={t("credit_price")} value={summ} setvalue={setsumm}/>
            </Col>
           
            <Col className='filter_col filter_col_right'  xl={6} lg={8} md={12} sm={24}>
                <SimpleInput percent={true} placeholder={''} label={t("percent")} value={percent} setvalue={setpercent}/>
            </Col>
            
            <Col className='filter_col filter_col_right' xl={6} lg={8} md={12} sm={24}>
                <SimpleInput placeholder={''} label={t("credit_month")} value={month} setvalue={setmonth}/>
            </Col>
           
            <Col className='filter_col filter_col_right'  xl={6} lg={8} md={12} sm={24}>
                <SimpleInput percent={true} placeholder={''} label={t("init_percent")} value={init_pay_percent} setvalue={setinit_pay_percent}/>
            </Col>
           
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("bonus_month")} value={bonus_month__ggte} setvalue={setbonus_month__ggte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={bonus_month__lte} setvalue={setbonus_month__lte}/>
            </Col>
            
              </>:<></>
            }
             {
              category_old===5?<>
              <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="bozor" label={t("b_type")} value={b_type__in} setvalue={setb_type__in} data={b_type_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handlecmtype} name="cmtype" label={t("car_type")} value={cmtype} setvalue={setcmtype} data={[{id:0, name_ru:"Все", name_uz:"Barchasi"},...cmtype_data]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handlectype} name="ctype" label={t("marka")} value={ctype} setvalue={setctype} data={[{id:0, name_ru:"Все", name_uz:"Barchasi"},...ctype_data]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <SimpleSelect onchange={handlemodel} name="model" label={t("model")} value={model} setvalue={setmodel} data={[{id:0, name_ru:"Все", name_uz:"Barchasi"},...model_data]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="position__in" label={t("position")} value={position__in} setvalue={setposition__in} data={position_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="kuzov__in" label={t("kuzov")} value={kuzov__in} setvalue={setkuzov__in} data={kuzov_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="transmission__in" label={t("transmission")} value={transmission__in} setvalue={settransmission__in} data={transmission_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="drive_unit__in" label={t("drive_unit")} value={drive_unit__in} setvalue={setdrive_unit__in} data={drive_unit_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="color__in" label={t("car_color")} value={color__in} setvalue={setcolor__in} data={color_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="color_state__in" label={t("color_state")} value={color_state__in} setvalue={setcolor_state__in} data={color_state_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
            <MultipleSelect name="engine_type__in" label={t("engine_type")} value={engine_type__in} setvalue={setengine_type__in} data={engine_type_data}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="autosalon" label={t("autosalon_has")} value={autosalon} setvalue={setautosalon} data={[
                    {id:1, name_ru:"Да", name_uz:"Ha"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="rent" label={t("rent_has")} value={rent} setvalue={setrent} data={[
                    {id:1, name_ru:"Да", name_uz:"Ha"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' xl={6} lg={8} md={12} sm={24}>
                <MultipleSelect name="long_term_pay" label={t("long_term_pay_has")} value={long_term_pay} setvalue={setlong_term_pay} data={[
                    {id:1, name_ru:"Да", name_uz:"Ha"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("probeg")} value={probeg__gte} setvalue={setprobeg__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={probeg__lte} setvalue={setprobeg__lte}/>
            </Col>
            
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={t("car_year")} value={year__gte} setvalue={setyear__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={year__lte} setvalue={setyear__lte}/>
            </Col>
            <Col className='filter_col filter_col_right' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("dan")} label={money_type===1?t("price_uzs"):t("price_usd")} value={price__gte} setvalue={setprice__gte}/>
            </Col>
            <Col className='filter_col filter_col_left' xl={3} lg={4} md={6} sm={12}>
                <SimpleInput placeholder={t("gacha")} label={" "} value={price__lte} setvalue={setprice__lte}/>
            </Col>
              </>:<></>
            }
           
           


            <div className='filter_btns'>
          <button onClick={restoreFilter} className='restore_btn'>{t("restore")}</button>
          <button className='save_btn' onClick={searchData}>{t("search")}</button>
        </div>
           </Row>
       
        </motion.div>
        <motion.div initial={{y:100, opacity:0}}  animate={{ y: 0, opacity:1 }}
    transition={{ ease: "easeOut", duration: 0.5 }} className={`products_sorter`}>
            <p className='sorter_title'>Saralash:</p>
            <div className='sorter_box'>
            {category_old!==4 && category_old!==8?  <div className='sorter_select'>
                <SimpleSelect onchange={handlechange} name="sorter_select" label={""} value={sorter_select} setvalue={setsorter_select} data={[
                    {id:1, name_ru:"Самый дешевый", name_uz:"Eng arzon"},
                    {id:2, name_ru:"Самый дорогой", name_uz:"Eng qimmat"},
                    {id:3, name_ru:"Самый новый", name_uz:"Eng yangi"},
                    {id:4, name_ru:"Самый старый", name_uz:"Eng eski"},
                ]}/>
              </div>:<></>}
              <div className='sorter_shape'>
                <button onClick={()=>setshape(1)} className={shape===1?"active_shape":''}><TbLayoutList /></button>
                <button onClick={()=>setshape(2)} className={shape===2?"active_shape":''}><TbCategory /></button>
              </div>
              {category_old!==4 && category_old!==8?  <div className='sorter_shape money_type'>
                <button onClick={()=>setmoney_type(1)} className={money_type===1?"active_shape":''}>UZS</button>
                <span>|</span>
                <button onClick={()=>setmoney_type(2)} className={money_type===2?"active_shape":''}>USD</button>
              </div>:<></>}
            </div>
        </motion.div>
        
        <p className='axios_send' style={{display:"none"}}>{axios_send}</p>
        <p className='page' style={{display:"none"}}>{page}</p>
        <p className='next' style={{display:"none"}}>{next}</p>
    </>
      
      <Row>

        {category===1?<>
          {big_home_data.map((item, key)=>{
            if(shape===1){
              return(<CardHome data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardHomeMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===2?<>
          {big_home_service_data.map((item, key)=>{
            if(shape===1){
              return(<CardHomeService data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardHomeServiceMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===3?<>
          {big_home_product_data.map((item, key)=>{
            if(shape===1){
              return(<CardHomeProduct data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardHomeProductMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===4?<>
          {big_home_credit_data.map((item, key)=>{
            if(shape===1){
              return(<CardHomeCredit data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardHomeCreditMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===5?<>
          {big_car_data.map((item, key)=>{
            if(shape===1){
              return(<CardCar data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardCarMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===6?<>
          {big_car_service_data.map((item, key)=>{
            if(shape===1){
              return(<CardCarService data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardCarServiceMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===7?<>
          {big_car_product_data.map((item, key)=>{
            if(shape===1){
              return(<CardCarProduct data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardCarProductMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:category===8?<>
          {big_car_credit_data.map((item, key)=>{
            if(shape===1){
              return(<CardCarCredit data={item} key={key} number_key={key}/>)
            }else{
              return(<Col className='mini_card_col' lg={8} md={12} sm={24}><CardCarCreditMini  data={item} key={key} number_key={key}/></Col>)
            }
            
          })}
         </>:<>
        
        </>}
        
      </Row>
      <br/>
      {mini_loader?
        <div className='mini_loader_box'><ClipLoader size={50} color="#00a2b7" /></div>
:<></>}
    </div>
  )
}
