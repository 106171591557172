import axios from "axios"

export const api="https://api.uyavto.uz/api"

export const getTokenAccess=()=>{
    var token=window.localStorage.getItem("access_token_elon")
    return(token)

}

export const httpPostRequest=async(url:string, data:any)=>{
    var token=getTokenAccess()
    var res=await axios.post(api+url, data, {
        headers:{
            "Authorization":"Bearer "+token
        }
    })
    return(res)
}
export const httpPatchRequest=async(url:string, data:any)=>{
    var token=getTokenAccess()
    var res=await axios.patch(api+url, data, {
        headers:{
            "Authorization":"Bearer "+token
        }
    })
    return(res)
}
export const httpGetRequest=async(url:string, params?:any)=>{
    var token=getTokenAccess()
    var res=await axios.get(api+url, {
        params:params,
        headers:{
            "Authorization":"Bearer "+token
        }
    })
    return(res)
}
export const httpDeleteRequest=async(url:string, params?:any)=>{
    var token=getTokenAccess()
    var res=await axios.delete(api+url, {
        params:params,
        headers:{
            "Authorization":"Bearer "+token
        }
    })
    return(res)
}