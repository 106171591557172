import React, { useState } from 'react'
import SimpleInputText from '../../components/SimpleInputText'
import GoogleSignInButton from '../../components/GoogleSignInButton'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../host/Host'
import { useStore } from '../../store/Store'
import { dangerAlert } from '../../components/Alert'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

export default function Login() {
    const [email, setemail]=useState('')
    const [required, setrequired]=useState(false)
    const [password, setpassword]=useState('')
    const {setuser, setloader}=useStore()
    const navigate=useNavigate()
    const {t}=useTranslation()
    const login=()=>{
        setrequired(true)
        
        if(email.length!==0 && password.length!==0){
        setloader(true)
          axios.post(`${api}/login/`, {email, password}).then(res=>{
            window.localStorage.setItem("access_token_elon", res.data.access)
            setuser(res.data)
            setloader(false)
            navigate('/client')
          }).catch((err)=>{
            console.log(err)
            setloader(false)
            dangerAlert(t("erro_login_pass"))
          })
        }
     }
  return (
    <div className='box login_box_big'>
        <Helmet>
        <title>Kirish</title>
        <meta name="description" content="Uy avtomobilga oid barcha e'lonlaringizni tizimga yuklang va xaridor sizga bog'lanishini kuting" />
      </Helmet>
        <div className='login_box'>
            <h1>{t("login")}</h1>
            <div className='login_item'>
            <SimpleInputText required={required} placeholder={""} label={t("email")} value={email} setvalue={setemail}/>
            </div>
            <div className='login_item'>
            <SimpleInputText type='password' required={required} placeholder={""} label={t("password")} value={password} setvalue={setpassword}/>
            </div>
            <Link className='password_change' to={"/change-password"}>{t("miss_password")}</Link>
            {/* <div className='login_check'>
               <input className='check_input'  onChange={()=>{}} type="checkbox"/>
               <span>Foydalanish shartlarini qabul qiling</span>
            </div> */}
           
            <div className='filter_btns'>
                <GoogleSignInButton/>
                <Link to="/register" className='restore_btn'>{t("register")}</Link> 
               <button className='save_btn' onClick={login}>{t("login")}</button> 
               
            </div>
            </div>
       
    </div>
  )
}
