'use client';
import React, { useEffect, useState } from 'react'
import EmblaCarousel from '../../components/carousel/EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel';
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CarObject, useStore } from '../../store/Store';
import { httpGetRequest } from '../../host/Host';
import { filterPrice, formatFacebook, formatInstagram, formatTelegram, formatwebsite, responsive } from '../../components/Alert';
import avatar from '../../images/icons/random_avatar_man.jpg'
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF, FaGlobe, FaInstagram } from 'react-icons/fa6';
import { TbArrowBigLeftFilled } from 'react-icons/tb';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';

import CardCarMini from '../../components/cards/main_cards/CardCarMini';
const OPTIONS: EmblaOptionsType = {}
export default function CarProduct() {
    const {t}=useTranslation()
    const {id}=useParams()
    const navigate=useNavigate()
    const [data, setdata]=useState<CarObject|null>(null)
    const [number, setnumber]=useState(0)
    const {setloader, money_type, old_link}=useStore()
    const [as_add_data, setas_add_data]=useState<CarObject[]>([])
    const [user_add_data, setuser_add_data]=useState<CarObject[]>([])
    useEffect(()=>{
     
     getData()
    }, [id])
    const getData=async()=>{
        setloader(true)
        try{
            var res=await httpGetRequest(`/cars/${id}/detail/`)
            setdata(res.data)
            getAsAdds(res.data)
            if(res.data.created_by!==null){
              getUserAdds(res.data.created_by.id)
            }
            setloader(false)
            setnumber(number+1)
        }catch(err){
            setloader(false)
            navigate('/products?big_category=2&category=5')
        }
          
    }
    
    const getAsAdds=async(a:CarObject)=>{
      var config={
        ctype__in:a.ctype!==null?String(a.ctype.id):null, 
        model__in:a.model!==null?String(a.model.id):null, 
        position__in:a.position!==null?String(a.position.id):null, 
        page:1,
        status:1
      }
      try{
        var res=await httpGetRequest('/cars/', config)
        setas_add_data(res.data.results)
        setnumber(number+1)
        }catch(err){
        console.log(err)
        }
}
const getUserAdds=async(user_id:number)=>{
 try{
    var res=await httpGetRequest('/cars/'+user_id, {status:1, page:1})
    setuser_add_data(res.data.results)
    setnumber(number+1)
    }catch(err){
    console.log(err)
    }
}

    const formatOldLink = (link:string) => {
      if (link.length === 0) {
        return "/products?big_category=2&category=5";
      } else {
        return "/products" + link;
      }
    };
  return (
    <div className='box'>
        {data!==null?
        <>
        <div  className='product_img_box'>
        <div  className='product_img_box_box'>
         <div className='product_img'>
         <EmblaCarousel images={data.images} options={OPTIONS} />
        
         </div>
         </div>
         </div>
         <div className='product_text'>
         <div className='product_text_head'>
          {data.created_by!==null?<div className='user_box'>
                <div className='avatar' style={{backgroundImage:`url(${data.created_by!==null && data.created_by.image!==null?data.created_by.image:avatar})`}}  />
                <p>{data.created_by.first_name}</p>
            </div>:<></>}
            <div className='add_contact_box'>
            <Link className='add_contact_icon' to={formatOldLink(old_link)}><TbArrowBigLeftFilled /></Link>
            {data.telegram!==null && data.telegram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatTelegram(data.telegram)}><FaTelegramPlane /></a>:<></>}
            {data.instagram!==null && data.instagram.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatInstagram(data.instagram)}><FaInstagram /></a>:<></>}
            {data.facebook!==null && data.facebook.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatFacebook(data.facebook)}><FaFacebookF /></a>:<></>}
            {data.website!==null && data.website.length!==0?<a target='_blank' rel='noreferrer' className='add_contact_icon' href={formatwebsite(data.website)}><FaGlobe /></a>:<></>}
            
            {data.phone!==null && data.phone.length!==0?<a href={`tel: ${data.phone}`}>{data.phone}</a>:<></>}
            </div>
         </div>
            <div className='product_text_head'>
                <div className='head_text_title'>
            <h1>{data.name}</h1>
            <p className='product_price'>{money_type===2?filterPrice(data.price_usd)+" $":filterPrice(data.price_uzs)+t(t(" so'm"))}</p>
            </div>
            </div>
            <div className='product_box'>
            {data.region!=null || data.district!=null?<div className='product_item'>
            <span>{t("address")} : {data.region!=null?data.region[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}, {data.district!=null?data.district[`name_${t("lang")==='ru'?'ru':'uz'}`]:''}</span>
            </div>:<></>}
            {data.b_type!=null?<div className='product_item'>
            <span>{t("b_type")} : {data.b_type[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.year!=null?<div className='product_item'>
            <span>{t("car_year")} : {data.year}</span>
            </div>:<></>}
           
            {data.cmtype!=null?<div className='product_item'>
            <span>{t("car_type")} : {data.cmtype[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.ctype!=null?<div className='product_item'>
            <span>{t("marka")} : {data.ctype[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.model!=null?<div className='product_item'>
            <span>{t("model")} : {data.model[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.position!=null?<div className='product_item'>
            <span>{t("position")} : {data.position[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.kuzov!=null?<div className='product_item'>
            <span>{t("kuzov")} : {data.kuzov[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.color!=null?<div className='product_item'>
            <span>{t("car_color")} : {data.color[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.color_state!=null?<div className='product_item'>
            <span>{t("color_state")} : {data.color_state[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.transmission!=null?<div className='product_item'>
            <span>{t("transmission")} : {data.transmission[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.drive_unit!=null?<div className='product_item'>
            <span>{t("drive_unit")} : {data.drive_unit[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
           
            {data.engine_type!=null?<div className='product_item'>
            <span>{t("engine_type")} : {data.engine_type[`name_${t("lang")==='ru'?'ru':'uz'}`]}</span>
            </div>:<></>}
            {data.autosalon!=null?<div className='product_item'>
            <span>{t("autosalon_has")} : {data.autosalon?t("yes"):t("no")}</span>
            </div>:<></>}
            {data.long_term_pay!=null?<div className='product_item'>
            <span>{t("long_term_pay_has")} : {data.long_term_pay?t("yes"):t("no")}</span>
            </div>:<></>}
            {data.rent!=null?<div className='product_item'>
            <span>{t("rent_has")} : {data.rent?t("yes"):t("no")}</span>
            </div>:<></>}
            {data.probeg!=null?<div className='product_item'>
            <span>{t("probeg")} : {data.probeg}</span>
            </div>:<></>}
            {data.capacity!=null?<div className='product_item'>
            <span>{t("capacity")} : {data.capacity}</span>
            </div>:<></>}
           
            
            
          
            </div>
            
         </div>
         <div className="yandex_map">
         {data.video!==null?<div className="yandex_map_box">
            <div className='yandex_box'>
          <video controls src={data.video}></video>
        </div>
         </div>:<></>}
         </div>
         {data.additional!==null && data.additional.length!==0?<div className='product_comment'>
               <p dangerouslySetInnerHTML={{__html:data.additional}}/> 
            </div>:''}
            {as_add_data.length>1?<div className='add_car_box'>
       <h2 className='as_add_title'>{t("as_adds")}</h2>
       <Carousel 
       swipeable={true}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} 
       infinite={true}
       autoPlay={false}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
       >
 {as_add_data.map((item, key)=>{
 if(item.id!==Number(id)){
  return(<div className='as_add_car_card'>
  <CardCarMini  data={item} key={key} number_key={key}/>
      </div>)
  
}
 })}
 
 
  
</Carousel>
       </div>:<></>}

       {user_add_data.length>1?<div className='add_car_box'>
       <h2 className='as_add_title'>{t("user_adds")}</h2>
       <Carousel 
       swipeable={true}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} 
       infinite={true}
       autoPlay={false}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
       >
 {user_add_data.map((item, key)=>{
  if(item.id!==Number(id)){
    return(<div className='as_add_car_card'>
    <CardCarMini  data={item} key={key} number_key={key}/>
        </div>)
    
  }
})}
 
</Carousel>
       </div>:<></>}
            
            
            </>
            :<></>}
    </div>
  )
}
