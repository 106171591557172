import { create, SetState } from 'zustand';

interface UserObject {
  first_name: string;
  last_name: string;
  facebook: string;
  telegram: string;
  website: string;
  instagram: string;
  image: string;
  email: string;
  uid: string;
  phone: string;
  district: TypeObject;
  gender: string;
  region: TypeObject;
}
export interface HomeObject {
  created_at: string|null;
  additional: string|null;
  agreed: boolean|null;
  furnished: boolean|null;
  makler: boolean|null;
  b_type:TypeObject;
  district:TypeObject;
  house_type:TypeObject;
  mat_type:TypeObject;
  items_in:TypeObject[];
  near:TypeObject[];
  id:number;
  status:number;
  floor:number|null;
  viewed:number|null;
  created_by:UserObject|null;
  video:string|null;
  year:number|null;
  floors:number|null;
  rooms:number|null;
  facebook: string|null;
  telegram: string|null;
  latitude: string|null;
  name: string|null;
  longitude: string|null;
  website: string|null;
  instagram: string|null;
  surface: number|null;
  image: ImageObject;
  images: ImageObject[];
  phone: string|null;
  price_usd: string|null;
  price_uzs: string|null;
  region: TypeObject;
  repair: TypeObject;
  sanuzel: TypeObject;
}
export interface ProductObject {
  created_at: string|null;
  additional: string|null;
  product_type:TypeObject;
  subproduct_type:TypeObject;
  id:number;
  video:string|null;
  created_by:UserObject|null;
  status:number;
  facebook: string|null;
  telegram: string|null;
  name: string|null;
  longitude: string|null;
  website: string|null;
  viewed:number|null;
  instagram: string|null;
  image: ImageObject;
  images: ImageObject[];
  phone: string|null;
  price_usd: string|null;
  price_uzs: string|null;
  region: TypeObject;
  district:TypeObject;
}
export interface ServiceObject {
  created_at: string|null;
  additional: string|null;
  service_types:TypeObject[];
  district:TypeObject;
  payment_type:TypeObject;
  gender: string|null;
  video:string|null;
  id:number;
  viewed:number|null;
  created_by:UserObject|null;
  status:number;
  facebook: string|null;
  telegram: string|null;
  name: string|null;
  fullname: string|null;
  longitude: string|null;
  website: string|null;
  instagram: string|null;
  image: ImageObject;
  images: ImageObject[];
  phone: string|null;
  experience: string|null;
  price_usd: string|null;
  price_uzs: string|null;
  region: TypeObject;
}
export interface CreditObject {
  created_at: string|null;
  additional: string|null;
  itype:TypeObject;
  b_type:TypeObject;
  district:TypeObject;
  video:string|null;
  id:number;
  created_by:UserObject|null;
  status:number;
  facebook: string|null;
  telegram: string|null;
  name: string|null;
  longitude: string|null;
  latitude: string|null;
  website: string|null;
  instagram: string|null;
  viewed:number|null;
  image: ImageObject;
  images: ImageObject[];
  phone: string|null;
  summ_min: number|string|null;
  summ_max: number|string|null;
  month_min: number|string|null;
  month_max: number|string|null;
  p_min: string|null;
  bonus_month: number|null;
  p_max: string|null;
  init_pay_percent_min: string|null;
  init_pay_percent_max: string|null;
  region: TypeObject;
}
export interface CarObject {
  created_at: string|null;
  additional: string|null;
  agreed: boolean|null;
  autosalon: boolean|null;
  rent: boolean|null;
  long_term_pay: boolean|null;
  capacity:number|null;
  district:TypeObject;
  b_type:TypeObject;
  color:TypeObject;
  created_by:UserObject|null;
  color_state:TypeObject;
  ctype:TypeObject;
  cmtype:TypeObject;
  drive_unit:TypeObject;
  engine_type:TypeObject;
  kuzov:TypeObject;
  model:TypeObject;
  position:TypeObject;
  transmission:TypeObject;
  id:number;
  year:number|null;
  video:string|null;
  viewed:number|null;
  status:number;
  probeg:number|null;
  floors:number|null;
  rooms:number|null;
  facebook: string|null;
  telegram: string|null;
  latitude: string|null;
  name: string|null;
  longitude: string|null;
  website: string|null;
  instagram: string|null;
  image: ImageObject;
  images: ImageObject[];
  phone: string|null;
  price_usd: string|null;
  price_uzs: string|null;
  region: TypeObject;
  repair: TypeObject;
  sanuzel: TypeObject;
}

export interface TypeObject {
  id: number;
  image?: string;
  name_ru: string;
  name_uz: string;
}
export interface ImageObject {
  id: number;
  image: string;
}

interface YourStore {
  big_category: number;
  setbig_category: (newValue: number) => void;
  number_data: number;
  setnumber_data: (newValue: number) => void;
  edit_home: HomeObject | null;
  setedit_home: (newValue: HomeObject | null) => void;
  loader: boolean;
  setloader: (newValue: boolean) => void;
  user: UserObject | null;
  setuser: (newValue: UserObject | null) => void;
  category: number;
  setcategory: (newValue: number) => void;
  shape: number;
  setshape: (newValue: number) => void;
  money_type: number;
  setmoney_type: (newValue: number) => void;
  search: string;
  setsearch: (newValue: string) => void;
  old_link: string;
  setold_link: (newValue: string) => void;
  isOpen: boolean;
  setisOpen: (newValue: boolean) => void;
  b_type_data: TypeObject[]; 
  setb_type_data: (newValue: TypeObject[]) => void; 
  big_home_data: HomeObject[]; 
  setbig_home_data: (newValue: HomeObject[]) => void; 
  big_home_product_data: HomeObject[]; 
  setbig_home_product_data: (newValue: HomeObject[]) => void; 
  
  house_product_type_data: TypeObject[]; 
  sethouse_product_type_data: (newValue: TypeObject[]) => void; 
  car_product_type_data: TypeObject[]; 
  setcar_product_type_data: (newValue: TypeObject[]) => void; 
  
  credit_type_data: TypeObject[]; 
  setcredit_type_data: (newValue: TypeObject[]) => void; 
  kuzov_data: TypeObject[]; 
  setkuzov_data: (newValue: TypeObject[]) => void; 
  engine_type_data: TypeObject[]; 
  setengine_type_data: (newValue: TypeObject[]) => void; 
  transmission_data: TypeObject[]; 
  settransmission_data: (newValue: TypeObject[]) => void; 
  drive_unit_data: TypeObject[]; 
  setdrive_unit_data: (newValue: TypeObject[]) => void; 
  color_data: TypeObject[]; 
  setcolor_data: (newValue: TypeObject[]) => void; 
  color_state_data: TypeObject[]; 
  setcolor_state_data: (newValue: TypeObject[]) => void; 
  ctype_data: TypeObject[]; 
  setctype_data: (newValue: TypeObject[]) => void; 
  cmtype_data: TypeObject[]; 
  setcmtype_data: (newValue: TypeObject[]) => void; 
 

  house_type_data: TypeObject[]; 
  sethouse_type_data: (newValue: TypeObject[]) => void; 
  house_service_types_data: TypeObject[]; 
  sethouse_service_types_data: (newValue: TypeObject[]) => void; 
  house_service_payment_types_data: TypeObject[]; 
  sethouse_service_payment_types_data: (newValue: TypeObject[]) => void; 
  car_service_types_data: TypeObject[]; 
  setcar_service_types_data: (newValue: TypeObject[]) => void; 
  car_service_payment_types_data: TypeObject[]; 
  setcar_service_payment_types_data: (newValue: TypeObject[]) => void; 
  items_in_data: TypeObject[]; 
  setitems_in_data: (newValue: TypeObject[]) => void; 
  mat_type_data: TypeObject[]; 
  setmat_type_data: (newValue: TypeObject[]) => void; 
  near_data: TypeObject[]; 
  setnear_data: (newValue: TypeObject[]) => void; 
  repair_data: TypeObject[]; 
  setrepair_data: (newValue: TypeObject[]) => void; 
  sanuzel_data: TypeObject[]; 
  setsanuzel_data: (newValue: TypeObject[]) => void; 
  region_data: TypeObject[]; 
  setregion_data: (newValue: TypeObject[]) => void; 
  district_data: TypeObject[]; 
  setdistrict_data: (newValue: TypeObject[]) => void; 
}

export const useStore = create<YourStore>((set: SetState<YourStore>) => ({
  user: null,
  setuser: (val: UserObject | null) => set({ user: val }),
  edit_home: null,
  setedit_home: (val: HomeObject | null) => set({ edit_home: val }),
  loader: false,
  setloader: (val: boolean) => set({ loader: val }),
  big_category: 1,
  setbig_category: (val: number) => set({ big_category: val }),
  number_data: 1,
  setnumber_data: (val: number) => set({ number_data: val }),
  shape: 2,
  setshape: (val: number) => set({ shape: val }),
  money_type: 1,
  setmoney_type: (val: number) => set({ money_type: val }),
  category: 1,
  setcategory: (val: number) => set({ category: val }),
  search: '',
  setsearch: (val: string) => set({ search: val }),
  old_link: '',
  setold_link: (val: string) => set({ old_link: val }),
  isOpen: false,
  setisOpen: (val: boolean) => set({ isOpen: val }),
  
  big_home_data: [],
  setbig_home_data: (val: HomeObject[]) => set(({number_data})=>({ big_home_data: val, number_data:(number_data+1) })),
  big_home_product_data: [],
  setbig_home_product_data: (val: HomeObject[]) => set(({number_data})=>({ big_home_data: val, number_data:(number_data+1) })),
  house_service_types_data: [],
  sethouse_service_types_data: (val: TypeObject[]) => set({ house_service_types_data: val }),
  house_service_payment_types_data: [],
  sethouse_service_payment_types_data: (val: TypeObject[]) => set({ house_service_payment_types_data: val }),
  car_service_types_data: [],
  setcar_service_types_data: (val: TypeObject[]) => set({ car_service_types_data: val }),
  car_service_payment_types_data: [],
  setcar_service_payment_types_data: (val: TypeObject[]) => set({ car_service_payment_types_data: val }),
  house_product_type_data: [],
  sethouse_product_type_data: (val: TypeObject[]) => set({ house_product_type_data: val }),
  car_product_type_data: [],
  setcar_product_type_data: (val: TypeObject[]) => set({ car_product_type_data: val }),

  credit_type_data: [],
  setcredit_type_data: (val: TypeObject[]) => set({ credit_type_data: val }),
  kuzov_data: [],
  setkuzov_data: (val: TypeObject[]) => set({ kuzov_data: val }),
  engine_type_data: [],
  setengine_type_data: (val: TypeObject[]) => set({ engine_type_data: val }),
  transmission_data: [],
  settransmission_data: (val: TypeObject[]) => set({ transmission_data: val }),
  drive_unit_data: [],
  setdrive_unit_data: (val: TypeObject[]) => set({ drive_unit_data: val }),
  color_data: [],
  setcolor_data: (val: TypeObject[]) => set({ color_data: val }),
  color_state_data: [],
  setcolor_state_data: (val: TypeObject[]) => set({ color_state_data: val }),
  ctype_data: [],
  setctype_data: (val: TypeObject[]) => set({ ctype_data: val }),
  cmtype_data: [],
  setcmtype_data: (val: TypeObject[]) => set({ cmtype_data: val }),
 
  b_type_data: [],
  setb_type_data: (val: TypeObject[]) => set({ b_type_data: val }),
  house_type_data: [],
  sethouse_type_data: (val: TypeObject[]) => set({ house_type_data: val }),
  items_in_data: [],
  setitems_in_data: (val: TypeObject[]) => set({ items_in_data: val }),
  mat_type_data: [],
  setmat_type_data: (val: TypeObject[]) => set({ mat_type_data: val }),
  near_data: [],
  setnear_data: (val: TypeObject[]) => set({ near_data: val }),
  repair_data: [],
  setrepair_data: (val: TypeObject[]) => set({ repair_data: val }),
  sanuzel_data: [],
  setsanuzel_data: (val: TypeObject[]) => set({ sanuzel_data: val }),
  region_data: [],
  setregion_data: (val: TypeObject[]) => set({ region_data: val }),
  district_data: [],
  setdistrict_data: (val: TypeObject[]) => set({ district_data: val }),
}));