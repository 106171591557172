import { Col, Row, Tooltip } from 'antd'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import SimpleSelect from '../../../../components/SimpleSelect'
import MultipleSelect from '../../../../components/MultipleSelect'
import { ImageType, bolData, closeSelect} from '../../../../components/Data'
import SimpleInput from '../../../../components/SimpleInput'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import SimpleInputText from '../../../../components/SimpleInputText'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { useStore } from '../../../../store/Store'
import { FaTriangleExclamation } from 'react-icons/fa6'
import { dangerAlert, scrollDivToTop } from '../../../../components/Alert'
import { api, httpDeleteRequest, httpGetRequest, httpPatchRequest, httpPostRequest } from '../../../../host/Host'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleInputPhone from '../../../../components/SimpleInputPhone'
import { useTranslation } from 'react-i18next'
export default function HomeAnnoucmentEdit() {
    const {region_data, district_data, setdistrict_data, b_type_data, mat_type_data, repair_data, items_in_data, house_type_data, near_data, sanuzel_data}=useStore()
    const [b_type, setb_type]=useState(0)
    const [furnished, setfurnished]=useState(0)
    const [near, setnear]=useState<string | null>('[]')
    const [items_in, setitems_in]=useState<string | null>('[]')
    const [repair, setrepair]=useState(0)
    const [makler, setmakler]=useState(0)
    const [required, setrequired]=useState(false)
    const [mat_type, setmat_type]=useState(0)
    const [image, setimage]=useState<number|null>(null)
    const [old_image_id, setold_image_id]=useState<number|null>(null)
    const [name, setname]=useState('')
    const [agreed, setagreed]=useState(false)
    const [number, setnumber]=useState(1)
    const [coors, setcoors]=useState([41.355178,69.287824])
    const [images, setimages]=useState<File[]>([])
    const [images_old, setimages_old]=useState<ImageType[]>([])
    const [images_delete, setimages_delete]=useState<number[]>([])
    const [region, setregion]=useState(0)
    const [sanuzel, setsanuzel]=useState(0)
    const [video, setvideo]=useState<File[]>([])
    const [video_str, setvideo_str]=useState(null)
    const [district, setdistrict]=useState(0)
    const [facebook, setfacebook]=useState('')
    const [instagram, setinstagram]=useState('')
    const [website, setwebsite]=useState('')
    const [telegram, settelegram]=useState('')
    const [phone, setphone]=useState('')
    const [price_uzs, setprice_uzs]=useState<number|string>('')
    const [price_usd, setprice_usd]=useState<number|string>('')
    const [rooms, setrooms]=useState<number|string>('')
    const [floor, setfloor]=useState<number|string>('')
    const [floors, setfloors]=useState<number|string>('')
    const [surface, setsurface]=useState<number|string>('')
    const [year, setyear]=useState<number|string>('')
    const [house_type, sethouse_type]=useState(0)
    const [additional, setadditional] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const {setloader}=useStore()
    const navigate=useNavigate()
    const videoInputRef = useRef<HTMLInputElement | null>(null);
    const {id}=useParams()
    const {t} =useTranslation()
    useEffect(()=>{
     if(isNaN(Number(id))){
      navigate("/client/home/home")
     }
     
     
     getData()
    }, [])
    const getData=async()=>{
      setloader(true)
      try{
         var res=await httpGetRequest(`/houses/${id}/detail/`)
         if(res.data.b_type!=null){
          setb_type(res.data.b_type.id)
         }
         if(res.data.image!=null){
          setold_image_id(res.data.image.id)
          }
         if(res.data.house_type!=null){
         sethouse_type(res.data.house_type.id)
         }
         if(res.data.mat_type!=null){
         setmat_type(res.data.mat_type.id)
         }
         if(res.data.video!==null){
          setvideo_str(res.data.video)
         }
         if(res.data.name!=null){
         setname(res.data.name)
         }
         if(res.data.agreed!=null){
         setagreed(res.data.agreed)
         }
         if(res.data.instagram!=null){
         setinstagram(res.data.instagram)
         }
         if(res.data.additional!=null){
         setadditional(res.data.additional)
         }
         if(res.data.facebook!=null){
         setfacebook(res.data.facebook)
         }
         if(res.data.floor!=null){
         setfloor(res.data.floor)
         }
         if(res.data.phone!=null){
         setphone(res.data.phone)
         }
         if(res.data.price_usd!=null){
         setprice_usd(Number(res.data.price_usd))
         }
         if(res.data.price_uzs!=null){
         setprice_uzs(Number(res.data.price_uzs))
         }
         if(res.data.items_in!=null){
         setitems_in(JSON.stringify(res.data.items_in.map((item:{id:number})=>(item.id))))
         }
         if(res.data.near!=null){
         setnear(JSON.stringify(res.data.near.map((item:{id:number})=>(item.id))))
         }
         if(res.data.floors!=null){
         setfloors(res.data.floors)
         }
         if(res.data.furnished!=null){
         setfurnished(res.data.furnished?1:2) 
         }
         if(res.data.makler!=null){
         setmakler(res.data.makler?1:2) 
         }
         if(res.data.latitude!=null && res.data.longitude!=null){
         setcoors([Number(res.data.latitude), Number(res.data.longitude)])
         }
         if(res.data.region!=null){
         setregion(res.data.region.id)
         }
         if(res.data.district!=null){
         setdistrict(res.data.district.id)
         }
         if(res.data.sanuzel!=null){
         setsanuzel(res.data.sanuzel.id)
         }
         if(res.data.repair!=null){
         setrepair(res.data.repair.id)
         }
         if(res.data.rooms!=null){
         setrooms(res.data.rooms)
         }
         if(res.data.surface!=null){
         setsurface(res.data.surface)
         }
         if(res.data.website!=null){
          setwebsite(res.data.website)
          }
          if(res.data.image!=null){
            setimage(res.data.image.id)
          }
          
          if(res.data.telegram!=null){
            settelegram(res.data.telegram)
            }
         if(res.data.year!=null){
         setyear(res.data.year)
         }
         if(res.data.region!=null){
          handleregion(res.data.region.id, 1)
         }
         if(res.data.images!=null){
            setimages_old(res.data.images)
         }
         setloader(false)
      }catch(err){
        setloader(false)
         navigate("/client/home/home")
      }
    }
    const onFinish=async()=>{
        if(phone!=null){
          var phone_new=phone.replaceAll("_", "")
        }else{
          var phone_new=""
        }
        
        if(name.length!==0 && phone_new.length===19 && region!==0 && district!==0 && price_uzs!=='' && price_usd!=='' && surface!=='' && house_type!==0){
          if(!(images.length!==0 || images_old.length!==0)){
            dangerAlert(t("error_image"))
          }else{
           
            setloader(true)
            var data={
              name,
              makler:bolData[makler],
              furnished:bolData[furnished],
              price_uzs:String(price_uzs),
              price_usd:String(price_usd),
              floor:String(floor).length===0?null:String(floor),
              rooms:String(rooms).length===0?null:String(rooms),
              floors:String(floors).length===0?null:String(floors),
              year:String(year).length===0?null:String(year),
              agreed,
              phone,
              instagram:instagram.length===0?null:instagram,
              facebook:facebook.length===0?null:facebook,
              website:website.length===0?null:website,
              telegram:telegram.length===0?null:telegram,
              latitude:coors[0],
              longitude:coors[1],
              region,
              district,
              surface,
              image:image!==null?image:old_image_id,
              b_type:b_type!==0?b_type:null,
              house_type:house_type,
              repair:repair!==0?repair:null,
              mat_type:mat_type!==0?mat_type:null,
              sanuzel:sanuzel!==0?sanuzel:null,
              near:near!=null && JSON.parse(near).length!==0?JSON.parse(near):null,
              additional,
              items_in:items_in!=null && JSON.parse(items_in).length!==0?JSON.parse(items_in):null,
              video:video.length===0?null:video[0]
            }
            console.log(data)
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
              if (value !== null) {
                formData.append(key, value);
                
              }
            }
            try{
              var res=await httpPatchRequest(`/houses/${id}/update/`, formData)
              if(images_delete.length!==0){
                await Promise.all(images_delete.map(async(item2:number, key:number)=>{
                  try{
                    var res2=await httpDeleteRequest(`/houses/images/${item2}/delete/`)
                  }catch(err2){
                    console.log(err2)
                  }
                }))
              }
              if(images.length!=0){
                await Promise.all(images.map(async(item, key:number)=>{
                  var config=new FormData
                  config.append('image', item)
                  config.append('house', res.data.id)
                  config.append('main', 'false')
                  try{
                    var res1=await httpPostRequest(`/houses/images/create/`, config)
                    
                  }catch(err1){
                    console.log(err1)
                  }
                }))
               
              }
             
              setloader(false)
              navigate("/client/home/home")
            }catch(err){
              setloader(false)
              dangerAlert(t("Error"))
              console.log(err)
            }
          
          }
         
        }else{
          dangerAlert(t("error_not_full"))
          setrequired(true)
          scrollDivToTop()
        }
    }
   
    const handleEditorChange = (content: string) => {
        setadditional(content);
      };
      const handlechange=()=>{

      }
      const deleteVideo=async()=>{
             try{
              setloader(true)
               var res=await httpDeleteRequest(`/houses/video/${id}/delete/`)   
               setvideo_str(null)
               setloader(false)
             }catch(err){
                 setloader(false)
             }
      }
    const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = e.target.files;
    
      if (selectedFiles) {
        var filesArray=[]
        for(let i=0; i<selectedFiles.length; i++){
          const fileSizeInBytes = selectedFiles[i].size;
          const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
          if (fileSizeInMegabytes <= 100) {
            filesArray.push(selectedFiles[i])
          }
           
        }
        if(filesArray.length!==selectedFiles.length){
          dangerAlert(t("error_video_mb"))
        }
        
        setvideo(filesArray)
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };
    const handleClick = (e: any) => {
        const coords = e.get('coords');
        setcoors(coords);
      };
      const handleCloseSelect=(e: React.MouseEvent<HTMLDivElement>)=>{
     
        if (e.target instanceof HTMLElement && e.target.className.indexOf("select")===-1 && e.target.parentElement instanceof HTMLElement && e.target.parentElement.className.indexOf("select")===-1) {
          // Handle the body click here
          closeSelect("a");
        }
       }
       const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
         
        if (selectedFiles) {
          var filesArray=[]
          for(let i=0; i<selectedFiles.length; i++){
            const fileSizeInBytes = selectedFiles[i].size;
            const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
            if (fileSizeInMegabytes <= 10) {
              filesArray.push(selectedFiles[i])
            }
             
          }
          if(filesArray.length!==selectedFiles.length){
            dangerAlert(`${t("lang")==='uz'?selectedFiles.length-filesArray.length:''} ${t("error_image_mb")}`)
          }
          
          var l=10-(images.length+images_old.length)
          if(l<0){
            l=0
          }
          setimages([...filesArray.slice(0, l), ...images]);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        }
      };
      const deleteImage=(key:number)=>{
        var a=images
        a.splice(key,1)
setimages(a)
setnumber(number+1)
      }
      const deleteImage_old=async(key:number)=>{
          var id_im=images_old[key].id       
          setimages_delete([...images_delete, images_old[key].id])
          var a=images_old
          a.splice(key,1)
  setimages_old(a)
  
  setnumber(number+1)
  if(image===id_im){
    setActiveImage()
  }
        
      }
      const setActiveImage=()=>{
        if(images_old.length!==0){
          setimage(images_old[0].id)
        }else{
            setimage(0)
        }
      }
      const handleregion=async(id_new:number, bol?:number)=>{
       
        if(bol===undefined){
          
          setdistrict(0)
        }
        
        setdistrict_data([])
        var res=await httpGetRequest('/districts/?region='+id_new)
         setdistrict_data(res.data)
    
      }
  return (
    <div  onClick={(e)=>{handleCloseSelect(e)}} className='announcment_client'>
        <div className={`announcment_filter client_open_filter`}>
            <div className={`announcment_filter_head`}>
            <h1>{t("add_elon")}</h1>
            </div>
           
            <div className='announcment_filter_body'>
            <div className='alert_text'> <FaTriangleExclamation className='alert_icon' />{t("alert_add_elon")}</div>
        <Row>
        <Col  className='filter_col' lg={8} md={24} sm={24}>
                <SimpleInputText max={70} required={required} placeholder={""} label={t("name_add")} value={name} setvalue={setname}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect required={required} onchange={handleregion} name="region" label={t("region")} value={region} setvalue={setregion} data={region_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
            <SimpleSelect required={required} onchange={handlechange} name="district" label={t("district")} value={district} setvalue={setdistrict} data={district_data}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("price_uzs")} value={price_uzs} setvalue={setprice_uzs}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("price_usd")} value={price_usd} setvalue={setprice_usd}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
            <div className={`input`}>
        <label>{t("agreed")}</label>
        <div className='agreed_box'><input className='agreed'  onClick={()=>{console.log(agreed);setagreed(!agreed)}} style={{width:'auto'}} type='checkbox' checked={agreed}/></div>
        
        </div>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput required={required} placeholder={" "} label={t("surface")} value={surface} setvalue={setsurface}/>
            </Col>
        <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  required={required} onchange={handlechange} name="house_type" label={t("house_type")} value={house_type} setvalue={sethouse_type} data={house_type_data}/>
            </Col>
        <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="b_type" label={t("b_type")} value={b_type} setvalue={setb_type} data={b_type_data}/>
            </Col>
            
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="makler" label={t("makler")} value={makler} setvalue={setmakler} data={[
                    {id:1, name_ru:"Есть", name_uz:"Bor"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="repair" label={t("repair")} value={repair} setvalue={setrepair} data={repair_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="mat_type" label={t("building_type")} value={mat_type} setvalue={setmat_type} data={mat_type_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="sanuzel" label={t("sanuzel")} value={sanuzel} setvalue={setsanuzel} data={sanuzel_data}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleSelect  onchange={handlechange} name="furnished" label={t("furnished")} value={furnished} setvalue={setfurnished} data={[
                    {id:1, name_ru:"Есть", name_uz:"Bor"},
                    {id:2, name_ru:"Нет", name_uz:"Yo'q"},
                ]}/>
            </Col>
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="near" label={t("near")} value={near} setvalue={setnear} data={near_data}/>
            </Col>
            
            <Col className='filter_col' lg={8} md={12} sm={24}>
                <MultipleSelect name="items_in" label={t("items_in")} value={items_in} setvalue={setitems_in} data={items_in_data}/>
            </Col>
            
            
            
              <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label={t("rooms")} value={rooms} setvalue={setrooms}/>
            </Col>
            
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label={t("floor")} value={floor} setvalue={setfloor}/>
            </Col>
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label={t("floors")} value={floors} setvalue={setfloors}/>
            </Col>
            
           
            
            <Col className='filter_col filter_col_right' lg={8} md={12} sm={24}>
                <SimpleInput placeholder={" "} label={t("build_year")} value={year} setvalue={setyear}/>
            </Col>
            <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputPhone required={required} placeholder={""} label={t("phone")} value={phone} setvalue={setphone}/>
                </Col>
                <Col className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Telegram")} value={telegram} setvalue={settelegram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Instagram")} value={instagram} setvalue={setinstagram}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("FaceBook")} value={facebook} setvalue={setfacebook}/>
                </Col>
                <Col  className='filter_col' lg={8} md={12} sm={24}>
                <SimpleInputText placeholder={""} label={t("Vebsite")} value={website} setvalue={setwebsite}/>
                </Col>
            <Col lg={16} md={12} sm={24}></Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_address")}</label>
            <div className='yandex_box'>
                
            <YMaps>
      <Map onClick={handleClick} defaultState={{
        center: coors,
        zoom: 14,
      }} width="100%" height="100%" options={{
        minZoom: 5,
        maxZoom: 20,
      }}>
        <Placemark geometry={coors} properties={{ hintContent: 'My Placemark', balloonContent: 'This is my placemark!' }} />
        <ZoomControl options={{ float: 'left' }} />
      </Map>
   
    </YMaps>
    </div>
            </Col>
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("additional")}</label>
           <div className='quill_box'> <ReactQuill
        theme="snow" // You can choose different themes like 'bubble' or 'snow'
        value={additional}
        onChange={handleEditorChange}
      /></div>
            </Col>
            {((images_old.length)+images.length)<10?  
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_image")}</label>
            <input ref={fileInputRef} onChange={handleFileChange} className='file_input' accept="image/*" type='file' multiple />
            <p className='file_text'>{t("alert_image")}</p>
            </Col>:<Col className='filter_col filter_col_right' lg={12} md={24} sm={24}></Col>}
          
            <Col className='filter_col filter_col_right' lg={12} md={24} sm={24}>
            <label>{t("select_video")}</label>
            <input ref={videoInputRef} onChange={handleVideoChange} className='file_input' accept="video/*" type='file' />
            <p className='file_text'>{t("alert_video")}</p>
            </Col>
            {images.map((item, key)=>{
                return(<Col key={key} className='filter_col' lg={6} md={12} sm={24}>
                    <div className='image_box'>
                       <img src={URL.createObjectURL(item)}/>
                       <div onClick={()=>{deleteImage(key)}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                       
                    </div>
                    
                </Col>)
            })}
            {images_old.map((item, key)=>{
                return(<Col key={key} className='filter_col' lg={6} md={12} sm={24}>
                    <div className='image_box'>
                       <img src={item.image}/>
                       <div onClick={()=>{deleteImage_old(key)}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                       <input className='active_image' onChange={()=>setimage(item.id)} type="radio" name="image" value={item.id} checked={item.id===image} />
                    </div>
                    
                </Col>)
            })}
             {video_str!==null?<Col className='filter_col' lg={12} md={12} sm={24}>
                    <div className='image_box video_box'>
                       <video src={video_str} controls />
                       <div onClick={()=>{deleteVideo()}} className='img_delete_btn'><IoCloseCircleSharp /></div>
                     </div>
                    
                </Col>:<></>}
        </Row>
        <div className='filter_btns'>
         
          <button onClick={onFinish} className='save_btn'>{t("save")}</button>
        </div>
        </div>
        </div>
       
    </div>
  )
}
