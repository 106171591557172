import React, {useState} from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup, UserCredential } from "@firebase/auth";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import { api, httpGetRequest } from "../host/Host";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/Store";



const GoogleSignInButton: React.FC = () => {
    const [bol, setbol]=useState<boolean>(true)
    const navigate=useNavigate()
    const {setuser, setloader}=useStore()
  const handleGoogleSignIn = async () => {
    setbol(false)
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    
    try {
      const result: UserCredential = await signInWithPopup(auth, provider);
      var token=await result.user.getIdToken()
      setloader(true)
      axios.post(`${api}/oauth2/`, {
        "uid": result.user.uid,
        "email": result.user.email,
        "email_verified": result.user.emailVerified,
        
        "name": result.user.displayName
      }, {
        headers:{
          "Authorization":"Bearer "+token
        }
      }).then(res=>{
        window.localStorage.setItem("access_token_elon", res.data.access)
        setuser(res.data)
        setbol(true)
        setloader(false)
        navigate('/client')
        
      }).catch((err:any)=>{
        console.log(err)
        setloader(false)
      })
      
    } catch (error) {
      console.error(error);
      setbol(true)
    }
  };
  return (
    <>
    {bol?<button className="google_btn restore_btn" onClick={handleGoogleSignIn}>
    <FcGoogle />
    </button>:<button  className="google_btn restore_btn">
    <FcGoogle />
    </button>}
    </>
    
  );
};

export default GoogleSignInButton;