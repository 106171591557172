import { create } from 'xmlbuilder2';

interface Url {
  loc: string;
  lastmod?: string;
}

export const generateSitemapXml = (urls: Url[]): string => {
  const xml: any = create({
    version: '1.0',
    encoding: 'UTF-8'
  })
    .ele('urlset', { xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9' });

  urls.forEach(url => {
    const urlElement: any = xml.ele('url');
    urlElement.ele('loc', {}, url.loc);
    if (url.lastmod) {
      urlElement.ele('lastmod', {}, url.lastmod);
    }
  });

  return xml.end({ prettyPrint: true });
};
