import i18n, { init } from "i18next";
import { initReactI18next } from 'react-i18next';
import uz from './uz.json'
import ru from './ru.json'
import cookie from 'react-cookies'
i18n.use(initReactI18next)
  .init({
    resources: {
        uz: {
            translation:uz,
          },
          ru: {
            translation:ru,
          },
   },
    
    lng: cookie.load('tilElon')?cookie.load('tilElon'):"ru",
    keySeparator: false,
    detection: {
        order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
        caches: ['cookie']
    },

    interpolation: {
        escapeValue: false
    }
  });

export default i18n;
